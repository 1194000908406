import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createCountry = createAsyncThunk(
  'country/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/country/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'Country created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCountry = createAsyncThunk(
  'country/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `country/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCountryDetails = createAsyncThunk(
  'country/single',
  async ({ countryId, dispatch }) => {
    try {
      console.log('ID:', countryId);
      const response = await get(`/country/admin/${countryId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCountryDetails = createAsyncThunk(
  'country/update',
  async ({ state, countryId, dispatch, handleClose }) => {
    try {
      const URL = `/country/admin/${countryId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'country updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCountry = createAsyncThunk(
  'country/delete',
  async ({ countryId, dispatch, handleClose }) => {
    try {
      const response = await del(`/country/admin/${countryId}/`);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'country deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCountries = createAsyncThunk(
  'country/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/country/all?page=${page && page}&search=${search && search}&limit=${limit && limit}`
      );
      console.log(response);
      if (response) {
        return {
          // response,
          countries: response?.countries,
          total: response?.total,
          page: response?.page,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
