import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { Stack, Typography, CircularProgress } from '@mui/material';

import * as hooks from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

// import { fDate, fTime } from 'src/utils/format-time';

// import { Link } from 'react-router-dom';
// import CopyToClipboard from 'react-copy-to-clipboard';

// import { fDate, fTime } from 'src/utils/format-time';

import { isNaN } from 'lodash';

import { deleteSale } from 'src/server/api/sale';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function LabmodelTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  loading,
  colSpan,
  sort,
  school,
}) {
  const { title, offer_price, price, _id, base_price } = row;

  const directCommission = school?.direct_commission;
  const hierarchyCommission = school?.hierarchy_commission;

  const priceValue = offer_price || price;
  const valuePrice = priceValue - base_price;
  const directCommissionValue = (valuePrice * directCommission) / 100;
  const hierarchyCommissionValue = (valuePrice * hierarchyCommission) / 100;

  const dispatch = useDispatch();
  const confirm = useBoolean();
  const popover = usePopover();
  const { role } = useGetRoles();
  const router = hooks.useRouter();
  // const { enqueueSnackbar } = useSnackbar();
  // const handleEditClick = (_id) => {
  //   navigate(paths.dashboard.sale_view(_id), {
  //     state: _id,
  //   });
  // };
  // eslint-disable-next-line no-shadow
  const handleEditClick = (id) => {
    router.push({
      pathname: paths.dashboard.sale_view(id),
      query: { state: id }, // Using query for state information
    });
  };

  // const handleDeleteConfirmClick = (_id) => {
  //   const credentials = {
  //     dispatch,
  //     saleId: _id,
  //   };
  //   dispatch(deleteSale(credentials));
  // };

  const handleDeleteConfirmClick = (id) => {
    const credentials = {
      dispatch,
      saleId: id,
      // Add other necessary properties if needed
    };

    dispatch(deleteSale(credentials));

    // Optionally, close the delete dialog if needed
    // handleCloseDeleteDialog();
  };
  // const handleCopy = () => {
  //   // You can add any additional logic here when the link is copied
  //   console.log('Link copied to clipboard');
  //   enqueueSnackbar('Link copied!', { variant: 'success' });
  // };

  // const handleColor = (value) => {
  //   if (value >= 80) {
  //     return 'success';
  //   }
  //   if (value >= 60) {
  //     return 'warning';
  //   }
  //   if (value >= 40) {
  //     return 'info';
  //   }
  //   return 'error';
  // switch (value) {
  //   case (value>=80):
  //     return 'success'
  //     case (value>=60):
  //       return 'warning'
  //       case (value>=40):
  //       return 'info'
  //   default:
  //     return 'danger'
  // }
  // };

  return (
    <>
      {loading ? (
        <TableRow>
          <TableCell colSpan={colSpan + 1} align="center">
            <CircularProgress size="16px" />
          </TableCell>
        </TableRow>
      ) : (
        <TableRow hover selected={selected}>
          {/* <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell> */}
          {/* <TableCell>
            <ListItemText
              primary={fDate(createdAt)}
              secondary={fTime(createdAt)}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell> */}
          <TableCell>
            <ListItemText
              primary={title}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>
          <TableCell>
            <Stack direction="row" alignItems="center" spacing={1}>
              {offer_price > 0 && (
                <Typography
                  variant="caption"
                  sx={{ color: 'text.secondary', textDecoration: 'line-through' }}
                >
                  ₹{price}
                </Typography>
              )}
              <Typography variant="caption" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                ₹{offer_price > 0 ? offer_price : price}
              </Typography>
            </Stack>
          </TableCell>
          <TableCell>
            {isNaN(directCommissionValue) ? 'N/A' : `₹${directCommissionValue.toFixed(2)}`}
          </TableCell>{' '}
          <TableCell>
            {isNaN(hierarchyCommissionValue) ? 'N/A' : `₹${hierarchyCommissionValue.toFixed(2)}`}
          </TableCell>
          <TableCell align="left">
            <Stack direction="row" spacing={1}>
              {/* View Button */}
              <Label
                variant="soft"
                sx={{
                  backgroundColor: '#EAF4FF',
                  color: '#005DC7',
                  textTransform: 'none',
                  cursor: 'not-allowed', // Change cursor to indicate disabled
                  opacity: 0.5, // Reduce opacity to visually indicate disabled state
                  borderRadius: '4px',
                  px: '12px',
                  height: 30,
                  fontWeight: 700,
                  pointerEvents: 'none', // Disable interactions
                }}
              >
                View
              </Label>

              {/* Copy Button */}
              <Label
                variant="soft"
                sx={{
                  backgroundColor: '#EAF4FF',
                  color: '#005DC7',
                  textTransform: 'none',
                  cursor: 'not-allowed', // Change cursor to indicate disabled
                  opacity: 0.5, // Reduce opacity to visually indicate disabled state
                  borderRadius: '4px',
                  px: '12px',
                  height: 30,
                  fontWeight: 700,
                  pointerEvents: 'none', // Disable interactions
                }}
              >
                <Iconify
                  icon="fluent:copy-16-filled"
                  sx={{ width: '16px', height: '16px', mr: 0.5 }}
                />
                Copy Link
              </Label>
            </Stack>
          </TableCell>
          {/* <TableCell>
            <ListItemText
              primary={fDate(startedDate)}
              secondary={fTime(startedDate)}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>
          <TableCell align="right" sx={{ px: 1 }}>
            <Link to={paths?.dashboard?.course_view(_id)}>
            <Button>View</Button>
            </Link>
            </TableCell>
          {/* <TableCell>
            <CopyToClipboard text={`${paths.auth.course}?ref='12344`} onCopy={handleCopy}>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                style={{ whiteSpace: 'nowrap' }}
              >
                Share Course
              </Button>
            </CopyToClipboard>
          </TableCell> */}
          {role === 'super_admin' && (
            <TableCell align="right" sx={{ px: 1 }}>
              <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      )}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => handleEditClick(_id)}
          // onClick={() => {
          //   onViewRow();
          //   popover.onClose();
          // }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>

        {/* <MenuItem>
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        // open={handleDeleteConfirmClick}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete '' ?`}
        action={
          <Button
            variant="contained"
            color="error"
            // onClick={onDeleteRow}
            onClick={() => handleDeleteConfirmClick(_id)}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

LabmodelTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  colSpan: PropTypes.number,
  sort: PropTypes.any,
  school: PropTypes.any,
};
