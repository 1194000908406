import { createSlice } from '@reduxjs/toolkit';

import {
  createBulkCertificate,
  getAllBulkCertificates,
  createBulkCertificateV5,
} from '../api/bulkCertificate';

export const bulkCertificateSlice = createSlice({
  name: 'bulkCertificate',
  initialState: {
    loading: false,
    error: {},
    bulkCertificates: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createBulkCertificate.pending]: (state) => {
      state.loading = true;
    },
    [createBulkCertificate.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.bulkCertificates = action.payload;
    },
    [createBulkCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // create bulk certificate v5

    [createBulkCertificateV5.pending]: (state) => {
      state.loading = true;
    },
    [createBulkCertificateV5.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.bulkCertificates = action.payload;
    },
    [createBulkCertificateV5.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllBulkCertificates.pending]: (state) => {
      state.loading = true;
    },
    [getAllBulkCertificates.fulfilled]: (state, action) => {
      state.loading = false;
      state.bulkCertificates = action.payload;
      state.error = {};
    },
    [getAllBulkCertificates.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = bulkCertificateSlice.actions;

export default bulkCertificateSlice.reducer;
