import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  verifyCertificate,
  createCertificateTemplate,
  deleteCertificateTemplate,
  getAllCertificateTemplate,
  getCertificateTemplateDetails,
  updateCertificateTemplateDetails,
} from '../api/certificateTemplate';

export const certificateTemplatelice = createSlice({
  name: 'certificateTemplate',
  initialState: {
    loading: false,
    error: {},
    // sale
    certificateTemplates: {},
    staffDropList: [],
    certificateTemplateDetails: {},
    certificate: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createCertificateTemplate.pending]: (state) => {
      state.loading = true;
    },
    [createCertificateTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      const jsonState = covertToJSON(state)?.certificateTemplates;
      const newTemplateList = jsonState?.certificateTemplates;
      const modifiedTemplateList = {
        ...jsonState,
        certificateTemplates: Array.isArray(newTemplateList)
          ? [action.payload, ...newTemplateList]
          : [action.payload],
      };
      state.certificateTemplates = modifiedTemplateList;
    },
    [createCertificateTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all school
    [getAllCertificateTemplate.pending]: (state) => {
      state.loading = true;
    },
    [getAllCertificateTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.certificateTemplates = action.payload;
      state.error = {};
    },
    [getAllCertificateTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // verify school
    [verifyCertificate.pending]: (state) => {
      state.loading = true;
    },
    [verifyCertificate.fulfilled]: (state, action) => {
      state.loading = false;
      state.certificate = action.payload;
      state.error = {};
    },
    [verifyCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single school details
    [getCertificateTemplateDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCertificateTemplateDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.certificateTemplateDetails = action.payload;
      state.error = {};
    },
    [getCertificateTemplateDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update school details
    [updateCertificateTemplateDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateCertificateTemplateDetails.fulfilled]: (state, action) => {
      state.loading = false;
      const jsonState = covertToJSON(state)?.certificateTemplates;
      const modifiedTemplateList = {
        ...jsonState,
        certificateTemplates: jsonState.certificateTemplates?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };
      state.certificateTemplates = modifiedTemplateList;
      state.error = {};
    },
    [updateCertificateTemplateDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete school
    [deleteCertificateTemplate.pending]: (state) => {
      state.loading = true;
    },
    [deleteCertificateTemplate.fulfilled]: (state, action) => {
      console.log('Action Payload:', action.payload);
      const jsonState = covertToJSON(state)?.certificateTemplates;
      console.log('JSON State :', jsonState);
      const modifiedSchoolList = {
        ...jsonState,
        certificateTemplates: jsonState?.certificateTemplates?.filter(
          (certificateTemplates) => certificateTemplates._id !== action.payload
        ),
      };
      state.loading = false;
      state.certificateTemplates = modifiedSchoolList;
      state.error = {};
    },
    [deleteCertificateTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = certificateTemplatelice.actions;

export default certificateTemplatelice.reducer;
