import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createState = createAsyncThunk(
  'state/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `state/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        dispatch(getAllState({ dispatch }));
        dispatch(activeSnack({ type: 'success', message: 'State created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllState = createAsyncThunk(
  'state/list',
  async ({ page, search, dispatch, limit, country }) => {
    try {
      const response = await get(
        `state/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&country=${country || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// export const getCountryDetails = createAsyncThunk(
//   'country/single',
//   async ({ countryId, dispatch }) => {
//     try {
//       console.log('ID:', countryId);
//       const response = await get(`/country/admin/${countryId}`);
//       if (response) {
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

export const updateStateDetails = createAsyncThunk(
  'state/update',
  async ({ state, stateId, dispatch, handleClose }) => {
    try {
      const URL = `/state/admin/${stateId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(getAllState({ dispatch }));
        dispatch(activeSnack({ type: 'success', message: 'state updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteState = createAsyncThunk('state/delete', async ({ stateId, dispatch }) => {
  try {
    const response = await del(`/state/admin/${stateId}/`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'state deleted Successfully' }));
      dispatch(getAllState({ dispatch }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

// export const getAllCountries = createAsyncThunk(
//   'country/list',
//   async ({ page, search, dispatch, limit }) => {
//     try {
//       const response = await get(
//         `/country/all?page=${page && page}&search=${search && search}&limit=${limit && limit}`
//       );
//       console.log(response);
//       if (response) {
//         return {
//           // response,
//           countries: response?.countries,
//           total: response?.total,
//           page: response?.page,
//         };
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );
