import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getAllState = createAsyncThunk(
  'state/list',
  async ({ page, search, limit, dispatch, countryId }) => {
    try {
      const response = await get(
        `/state/all?country=${countryId || ''}&page=${page || 1}&search=${
          search || ''
        }&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllDistrict = createAsyncThunk(
  'district/list',
  async ({ page, search, limit, dispatch, stateId }) => {
    try {
      console.log('api for district');

      const response = await get(
        `/district/all?state=${stateId || ''}&page=${page || 1}&search=${
          search || ''
        }&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
