import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Card,
  Stack,
  Button,
  CardMedia,
  TextField,
  Container,
  Typography,
  CircularProgress,
} from '@mui/material';

import rightTic from 'src/assets/other/rightTic.svg';
import invalidIcon from 'src/assets/other/invalid.svg';
import noCertificate from 'src/assets/other/noCertificate.svg';
import { verifyCertificate } from 'src/server/api/certificateTemplate';
import certificatePageBanner from 'src/assets/other/CertificateVerifyBanner.svg';

import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';

export default function CertificateVerificationPage() {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();

  const { certificate, loading } = useSelector((state) => ({
    loading: state.certificateTemplate.loading,
    certificate: state.certificateTemplate.certificate,
  }));
  const [certificateNumber, setCertificateNumber] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  // const [certificateLoading, setCertificateLoading] = useState(false);

  const handleVerify = () => {
    if (certificateNumber) {
      dispatch(verifyCertificate({ certificateNumber, dispatch, setIsVerify }));
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ width: '100%', minHeight: '55vh', bgcolor: '#F5F8FA', pb: 10 }}>
        {/* Full-Width Banner Section */}
        <Box
          sx={{
            width: '100%',
            height: 310,
            backgroundImage: `url(${certificatePageBanner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />

        <Stack direction="row" justifyContent="center" mt={-1.5} px={isMobile ? 2 : 0}>
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              gap: 1,
              p: 1.5,
              width: 550,
              // backgroundColor: '#F5F8FA',
              borderRadius: '12px',
              boxShadow: '0px 1px 2px 0px #6A6A6A29',
            }}
          >
            <TextField
              placeholder="Enter your certificate number"
              value={certificateNumber}
              onChange={(e) => setCertificateNumber(e.target.value)}
              sx={{
                borderRadius: 1,
                width: '90%',
                outline: 'none',
                bgcolor: '#FFFFFF',
                height: '50px',
                '& .MuiInputBase-root': {
                  height: '50px',
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerify}
              sx={{
                textTransform: 'none',
                background: 'linear-gradient(to bottom, #00B8D9, #006C9C)',
                color: '#FFFFFF',
                height: '50px',
                minWidth: '80px',
              }}
            >
              {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Verify'}
            </Button>
          </Card>
        </Stack>
        {certificateNumber && isVerify ? (
          <Stack direction="row" justifyContent="center" mt={5} px={isMobile ? 2 : 0}>
            {certificate?.exists ? (
              <Card
                variant="outlined"
                sx={{
                  borderRadius: '12px',
                  width: 550,
                  p: '20px',
                }}
              >
                {/* {certificateLoading && (
                <Box
                  sx={{
                    background: `linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)`,
                    backgroundSize: '400% 100%',
                    animation: 'gradientLoading 1.5s infinite',
                    width: 500,
                    height: 350,
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '@keyframes gradientLoading': {
                      '0%': { backgroundPosition: '100% 0' },
                      '100%': { backgroundPosition: '-100% 0' },
                    },
                  }}
                >
                  <Typography>Loading certificate...</Typography>
                </Box>
              )} */}
                <CardMedia
                  component="img"
                  src={certificate?.certificate?.image?.url || '/path-to-certificate-image.jpg'}
                  alt="Certificate"
                  sx={{ width: '100%', borderRadius: 1 }}
                />
                <Typography display="flex" alignItems="center" pt={2} gap={0.5}>
                  <CardMedia
                    component="img"
                    image={rightTic}
                    alt="Verified"
                    sx={{ width: 18, height: 18 }}
                  />
                  This certificate has been issued to{' '}
                  <Typography sx={{ fontWeight: 'bold', color: '#212B36', pl: 0.5 }}>
                    {certificate?.certificate?.name}
                  </Typography>
                </Typography>
              </Card>
            ) : (
              <Card
                variant="outlined"
                sx={{
                  borderRadius: '12px',
                  width: 550,
                  p: '20px',
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#FFF5F5',
                  }}
                >
                  <CardMedia
                    component="img"
                    src={noCertificate}
                    alt="No Certificate Found"
                    sx={{ width: '100%', borderRadius: 1 }}
                  />
                </Box>
                <Typography display="flex" alignItems="center" pt={2} gap={0.5}>
                  <CardMedia
                    component="img"
                    src={invalidIcon}
                    alt="Invalid"
                    sx={{ width: 18, height: 18 }}
                  />
                  Invalid Certificate No.
                </Typography>
              </Card>
            )}
          </Stack>
        ) : (
          ''
        )}
      </Box>
    </Container>
  );
}
