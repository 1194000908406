import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, post } from './http';
import { activeSnack } from '../store/common';

export const getExcelDetails = createAsyncThunk(
  'static/excel-download',
  async ({
    module_type,
    sort,
    date,
    search,
    partner,
    partner_type,
    lab_model,
    status,
    type,
    country,
    stateId,
    districtId,
    talukId,
    school,
    classID,
    student,
    student_exam,
    dispatch,
    listType,
    otherProductId,
    filterValue,
    saleItemType,
  }) => {
    try {
      const response = await get(
        `/static/admin/excel-download/${module_type || ''}?listType=${listType || ''}&sort=${sort || ''}&search=${
          search || ''
        }&lab_model=${lab_model || ''}&dateFrom=${(date && date.from) || ''}&dateTo=${
          (date && date.to) || ''
        }&partner=${partner || ''}&partner_type=${partner_type || ''}&status=${status || ''}&type=${
          type || ''
        }&country=${country || ''}&state=${stateId || ''}&district=${districtId || ''}&taluk=${talukId || ''}&school=${school || ''}&class=${classID || ''}&student=${
          student || ''
        }&student_exam=${student_exam || ''}&other_product=${otherProductId || ''}&filterValue=${filterValue || ''}&sale_item_type=${saleItemType || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      // console.log('error', error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getExcelDetailsV3 = createAsyncThunk(
  'static/excel-download/v3',
  async ({
    module_type,
    sort,
    date,
    search,
    partner,
    partner_type,
    lab_model,
    status,
    type,
    country,
    stateId,
    districtId,
    school,
    classID,
    student,
    student_exam,
    dispatch,
    listType,
    otherProductId,
    filterValue,
    state,
  }) => {
    try {
      const response = await post(
        `/static/v3/admin/excel-download/${module_type || ''}?listType=${listType || ''}&sort=${sort || ''}&search=${
          search || ''
        }&lab_model=${lab_model || ''}&dateFrom=${(date && date.from) || ''}&dateTo=${
          (date && date.to) || ''
        }&partner=${partner || ''}&partner_type=${partner_type || ''}&status=${status || ''}&type=${
          type || ''
        }&country=${country || ''}&state=${stateId || ''}&district=${districtId || ''}&school=${school || ''}&class=${classID || ''}&student=${
          student || ''
        }&student_exam=${student_exam || ''}&other_product=${otherProductId || ''}&filterValue=${filterValue || ''}`,
        state
      );

      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      // console.log('error', error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getExcelDetailsV2 = createAsyncThunk(
  'static/excel-download/v2',
  async ({
    module_type,
    sort,
    date,
    search,
    partner,
    partner_type,
    lab_model,
    status,
    type,
    country,
    school,
    classID,
    student,
    student_exam,
    dispatch,
    listType,
    otherProductId,
    filterValue,
  }) => {
    try {
      const response = await get(
        `/static/v2/admin/excel-download/${module_type || ''}?listType=${listType || ''}&sort=${sort || ''}&search=${
          search || ''
        }&lab_model=${lab_model || ''}&dateFrom=${(date && date.from) || ''}&dateTo=${
          (date && date.to) || ''
        }&partner=${partner || ''}&partner_type=${partner_type || ''}&status=${status || ''}&type=${
          type || ''
        }&country=${country || ''}&school=${school || ''}&class=${classID || ''}&student=${
          student || ''
        }&student_exam=${student_exam || ''}&other_product=${otherProductId || ''}&filterValue=${filterValue || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      // console.log('error', error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
