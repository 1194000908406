import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
// import {
//   //   createCountry,
//   //   getAllCountry,
//   //   deleteCountry,
//   //   getCountryDetails,
//   getAllDistrict,
// } from '../api/district';
import {
  getAllDistrict,
  createDistrict,
  deleteDistrict,
  updateDistrictDetails,
} from '../api/district';

export const districtSlice = createSlice({
  name: 'district',
  initialState: {
    loading: false,
    error: {},
    // staff
    district: {},
    staffDropList: [],
    DistrictDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createDistrict.pending]: (state) => {
      state.loading = true;
    },
    [createDistrict.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.district;
      const newDitrictsList = jsonState?.districts;

      const modifiedDistrictList = {
        ...jsonState,
        countries: Array.isArray(newDitrictsList)
          ? [action.payload, ...newDitrictsList]
          : [action.payload],
      };
      state.loading = false;
      state.CountryDetails = action.payload;
      state.district = modifiedDistrictList;
      state.error = {};
    },
    [createDistrict.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all country
    [getAllDistrict.pending]: (state) => {
      state.loading = true;
    },
    [getAllDistrict.fulfilled]: (state, action) => {
      state.loading = false;
      state.district = action.payload;
      state.error = {};
    },
    [getAllDistrict.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update District detiels

    [updateDistrictDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateDistrictDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.district;
      const modifiedDistrictList = {
        ...jsonState,
        districts: jsonState.districts?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.country = modifiedCountryList;
      state.DistrictDetails = action.payload;
      state.district = modifiedDistrictList;
      state.error = {};
    },

    [updateDistrictDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // // get single staff details
    // [getCountryDetails.pending]: (state) => {
    //   state.loading = true;
    // },
    // [getCountryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.error = {};
    // },
    // [getCountryDetails.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },
    // // update staff details
    // [updateCountryDetails.pending]: (state) => {
    //   state.loading = true;
    // },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.error = {};
    // },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.country;
    //   const modifiedCountryList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) =>
    //       i._id === action.payload._id ? action?.payload : i
    //     ),
    //   };

    //   state.loading = false;
    //   // state.country = modifiedCountryList;
    //   state.CountryDetails = action.payload;
    //   state.country = modifiedCountryList;
    //   state.error = {};
    // },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.country;
    //   console.log(jsonState)
    //   const modifiedCountryList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.partnerType = modifiedCountryList;
    //   state.error = {};
    // },
    // [updateCountryDetails.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },

    [deleteDistrict.pending]: (state) => {
      state.loading = true;
    },
    [deleteDistrict.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.district;
      const modifiedDistrictList = {
        ...jsonState,
        districts: jsonState.districts?.filter((district) => district._id !== action.payload),
      };
      state.loading = false;
      state.district = modifiedDistrictList;
      state.error = {};
    },
    [deleteDistrict.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = districtSlice.actions;

export default districtSlice.reducer;
