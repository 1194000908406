import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSale,
  getAllSale,
  deleteSale,
  getAllSaleV3,
  getSaleDetails,
  updateSaleDetails,
  getSubPartnerSale,
  getSinglePartnerSale,
} from '../api/sale';

export const saleSlice = createSlice({
  name: 'sale',
  initialState: {
    loading: false,
    error: {},
    // sale
    sale: {},
    saleV3: {},
    subPartnerSale: {},
    staffDropList: [],
    saleDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create sale details
    [createSale.pending]: (state) => {
      state.loading = true;
    },
    [createSale.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.saleItems;
      const oldSaleList = jsonState?.saleItems;

      const modifiedSaleItemList = {
        ...jsonState,
        saleItems: Array.isArray(oldSaleList) ? [action.payload, ...oldSaleList] : [action.payload],
      };
      state.loading = false;
      state.saleDetails = action.payload;
      state.sale = modifiedSaleItemList;
      state.error = {};
    },
    [createSale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all sale
    [getAllSale.pending]: (state) => {
      state.loading = true;
    },
    [getAllSale.fulfilled]: (state, action) => {
      state.loading = false;
      state.sale = action.payload;
      state.error = {};
    },
    [getAllSale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all sale v3
    [getAllSaleV3.pending]: (state) => {
      state.loading = true;
    },
    [getAllSaleV3.fulfilled]: (state, action) => {
      state.loading = false;
      state.saleV3 = action.payload;
      state.error = {};
    },
    [getAllSaleV3.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getSubPartnerSale.pending]: (state) => {
      state.loading = true;
    },
    [getSubPartnerSale.fulfilled]: (state, action) => {
      state.loading = false;
      state.subPartnerSale = action.payload;
      state.error = {};
    },
    [getSubPartnerSale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // single partner sale
    [getSinglePartnerSale.pending]: (state) => {
      state.loading = true;
    },
    [getSinglePartnerSale.fulfilled]: (state, action) => {
      state.loading = false;
      state.sale = action.payload;
      state.error = {};
    },
    [getSinglePartnerSale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single sale details
    [getSaleDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSaleDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.saleDetails = action.payload;
      state.error = {};
    },
    [getSaleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update sale details
    [updateSaleDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSaleDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.saleDetails = action.payload;
      state.error = {};
    },
    [updateSaleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete sale
    [deleteSale.pending]: (state) => {
      state.loading = true;
    },
    [deleteSale.fulfilled]: (state, action) => {
      // console.log('Action Payload:', action.payload);
      const jsonState = covertToJSON(state)?.sales;
      // console.log('JSON State :', jsonState);

      const modifiedSaleItemList = {
        ...jsonState,
        saleItems: jsonState.saleItems?.filter((sale) => sale._id !== action.payload),
      };
      state.loading = false;
      state.sale = modifiedSaleItemList;
      state.error = {};
    },
    [deleteSale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = saleSlice.actions;

export default saleSlice.reducer;
