import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createState,
  //   getAllCountry,
  deleteState,
  //   getCountryDetails,
  getAllState,
  updateStateDetails,
} from '../api/state';

export const stateSlice = createSlice({
  name: 'state',
  initialState: {
    loading: false,
    error: {},
    // staff
    stateList: {},
    staffDropList: [],
    StateDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createState.pending]: (state) => {
      state.loading = true;
    },
    [createState.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.stateList;
      const newStateList = jsonState?.states;

      const modifiedStateList = {
        ...jsonState,
        states: Array.isArray(newStateList) ? [action.payload, ...newStateList] : [action.payload],
      };
      state.loading = false;
      state.StateDetails = modifiedStateList;
      state.stateList = action.payload;
      state.error = {};
    },
    [createState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all country
    [getAllState.pending]: (state) => {
      state.loading = true;
    },
    [getAllState.fulfilled]: (state, action) => {
      state.loading = false;
      state.stateList = action.payload;
      state.error = {};
    },
    [getAllState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update state detiels

    [updateStateDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateStateDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.stateList;
      const modifiedStateList = {
        ...jsonState,
        states: jsonState.states?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      // state.country = modifiedCountryList;
      state.StateDetails = action.payload;
      state.state = modifiedStateList;
      state.error = {};
    },

    [updateStateDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // [getCountryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.error = {};
    // },
    // [getCountryDetails.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },
    // // update staff details
    // [updateCountryDetails.pending]: (state) => {
    //   state.loading = true;
    // },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.error = {};
    // },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.country;
    //   const modifiedCountryList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) =>
    //       i._id === action.payload._id ? action?.payload : i
    //     ),
    //   };

    //   state.loading = false;
    //   // state.country = modifiedCountryList;
    //   state.CountryDetails = action.payload;
    //   state.country = modifiedCountryList;
    //   state.error = {};
    // },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.country;
    //   console.log(jsonState)
    //   const modifiedCountryList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.partnerType = modifiedCountryList;
    //   state.error = {};
    // },
    // [updateCountryDetails.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },

    [deleteState.pending]: (state) => {
      state.loading = true;
    },
    [deleteState.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.stateList;
      const modifiedStateList = {
        ...jsonState,
        state: jsonState.states?.filter((stat) => stat._id !== action.payload),
      };
      state.loading = false;
      state.stateList = modifiedStateList;
      state.error = {};
    },
    [deleteState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = stateSlice.actions;

export default stateSlice.reducer;
