import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createLabModel = createAsyncThunk(
  'lab-model/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/lab-model/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'lab-model created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllLabModel = createAsyncThunk(
  'lab-model/list',
  async ({ page, search, limit, school, dispatch }) => {
    try {
      const response = await get(
        `/lab-model/admin/all?page=${page || 1}&search=${search || ''}&limit=${
          limit || 5
        }&school=${school || ''}`
      );
      console.log(response);
      if (response) {
        return {
          // response,
          labModels: response?.labModels,
          total: response?.total,
          page: response?.page,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getLabModelDetails = createAsyncThunk(
  'lab-model/single',
  async ({ labModelId, dispatch }) => {
    try {
      console.log('ID:', labModelId);
      const response = await get(`/lab-model/admin/${labModelId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateLabModelDetails = createAsyncThunk(
  'lab-model/update',
  async ({ state, labModelId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/lab-model/admin/${labModelId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        // if (navigate) {
        //   navigate();
        // }
        dispatch(activeSnack({ type: 'success', message: 'lab-model updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteLabModel = createAsyncThunk(
  'lab-model/delete',
  async ({ labModelId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/lab-model/admin/${labModelId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'lab-model deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getLabModelList = createAsyncThunk(
  'lab-model/list/all',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `/lab-model/all?page=${page && page}&search=${search && search}&limit=${limit && limit}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
