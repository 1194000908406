import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createCertificateTemplate = createAsyncThunk(
  'certificate_template/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/certificate-template/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Template Created successfully!!' }));
        return response;
      }
      return '';
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCertificateTemplate = createAsyncThunk(
  'certificate_template/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `/certificate-template/admin/all?page=${page || 1}&search=${search || ''}&limit=${
          limit || 10
        }`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCertificateTemplateDetails = createAsyncThunk(
  'certificate_template/single',
  async ({ LmsExamCertificateId, dispatch }) => {
    try {
      const response = await get(`/certificate-template/admin/${LmsExamCertificateId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCertificateTemplateDetails = createAsyncThunk(
  'certificate_template/update',
  async ({ state, templateId, dispatch, navigate, handleClose }) => {
    try {
      const URL = `/certificate-template/admin/${templateId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        if (navigate) {
          navigate();
        }
        dispatch(activeSnack({ type: 'success', message: 'Template updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCertificateTemplate = createAsyncThunk(
  'certificate_template/delete',
  async ({ templateId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/certificate-template/admin/${templateId}/`);
      if (response) {
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        dispatch(activeSnack({ type: 'success', message: 'Template deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const verifyCertificate = createAsyncThunk(
  'verify/certificate',
  async ({ certificateNumber, dispatch, setIsVerify }) => {
    try {
      const response = await get(`/student-certificate/verify/${certificateNumber}`);
      if (response) {
        if (setIsVerify) {
          setIsVerify(true);
        }
        // if (setCertificateLoading) {
        //   setCertificateLoading(true);
        //   setTimeout(() => {
        //     setCertificateLoading(false);
        //   }, 2000);
        // }
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
