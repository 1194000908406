import { createSlice } from '@reduxjs/toolkit';

import { getExcelDetails, getExcelDetailsV2, getExcelDetailsV3 } from '../api/excelDownload';

export const countrySlice = createSlice({
  name: 'country',
  initialState: {
    loading: false,
    error: {},
    ExcelData: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get Excel-data
    [getExcelDetails.pending]: (state) => {
      state.loading = true;
    },
    [getExcelDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.ExcelData = action.payload;
      state.error = {};
    },
    [getExcelDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getExcelDetailsV2.pending]: (state) => {
      state.loading = true;
    },
    [getExcelDetailsV2.fulfilled]: (state, action) => {
      state.loading = false;
      state.ExcelData = action.payload;
      state.error = {};
    },
    [getExcelDetailsV2.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getExcelDetailsV3.pending]: (state) => {
      state.loading = true;
    },
    [getExcelDetailsV3.fulfilled]: (state, action) => {
      state.loading = false;
      state.ExcelData = action.payload;
      state.error = {};
    },
    [getExcelDetailsV3.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = countrySlice.actions;

export default countrySlice.reducer;
