import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createTaluk,
  //   getAllCountry,
  //   deleteCountry,
  //   getCountryDetails,
  getAllTaluk,
  deleteTaluk,
  updateTalukDetails,
} from '../api/taluk';

export const talukSlice = createSlice({
  name: 'taluk',
  initialState: {
    loading: false,
    error: {},
    // staff
    taluk: {},
    staffDropList: [],
    TalukDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createTaluk.pending]: (state) => {
      state.loading = true;
    },
    [createTaluk.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.taluk;
      const newTalukList = jsonState?.taluks;

      const modifiedTalukList = {
        ...jsonState,
        countries: Array.isArray(newTalukList)
          ? [action.payload, ...newTalukList]
          : [action.payload],
      };
      state.loading = false;
      state.TalukDetails = action.payload;
      state.taluk = modifiedTalukList;
      state.error = {};
    },
    [createTaluk.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all country
    [getAllTaluk.pending]: (state) => {
      state.loading = true;
    },
    [getAllTaluk.fulfilled]: (state, action) => {
      state.loading = false;
      state.taluk = action.payload;
      state.error = {};
    },
    [getAllTaluk.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateTalukDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateTalukDetails.fulfilled]: (state, action) => {
      // const jsonState = covertToJSON(state)?.taluk;
      // const modifiedTalukList = {
      //   ...jsonState,
      //   taluks: jsonState.taluks?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      // };

      state.loading = false;
      // state.country = modifiedCountryList;
      state.TalukDetails = action.payload;
      state.taluk = action.payload;
      state.error = {};
    },

    [updateTalukDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // // get single staff details
    // [getCountryDetails.pending]: (state) => {
    //   state.loading = true;
    // },
    // [getCountryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.error = {};
    // },
    // [getCountryDetails.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },
    // // update staff details
    // [updateCountryDetails.pending]: (state) => {
    //   state.loading = true;
    // },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.error = {};
    // },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.country;
    //   const modifiedCountryList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) =>
    //       i._id === action.payload._id ? action?.payload : i
    //     ),
    //   };

    //   state.loading = false;
    //   // state.country = modifiedCountryList;
    //   state.CountryDetails = action.payload;
    //   state.country = modifiedCountryList;
    //   state.error = {};
    // },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.country;
    //   console.log(jsonState)
    //   const modifiedCountryList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.partnerType = modifiedCountryList;
    //   state.error = {};
    // },
    // [updateCountryDetails.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },

    [deleteTaluk.pending]: (state) => {
      state.loading = true;
    },
    [deleteTaluk.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.taluk;
      const modifiedTalukList = {
        ...jsonState,
        taluks: jsonState.taluks?.filter((taluk) => taluk._id !== action.payload),
      };
      state.loading = false;
      state.taluk = modifiedTalukList;
      state.error = {};
    },
    [deleteTaluk.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = talukSlice.actions;

export default talukSlice.reducer;
