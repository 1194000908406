import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { paths } from 'src/routes/routes/paths';

import { CurrencySwitch } from 'src/hooks/use-currency';
import { useResponsive } from 'src/hooks/use-responsive';

import bag from 'src/assets/Dashboard/stack.png';
import stack3 from 'src/assets/Dashboard/stack3.png';
import stack5 from 'src/assets/Dashboard/stack5.png';
import stack2 from 'src/assets/Dashboard/stack2.png';
import stack6 from 'src/assets/Dashboard/stack6.png';
import kit from 'src/assets/Dashboard/ic-glass-buy.png';

import BalanceView from './balance-view';
import ActiveUsers from './active-users';
import SalesEarningChart from './sales-earning-chart';
import PartnerCheckInWidgets from './admin-check-in-widgets';
// ----------------------------------------------------------------------

const SPACING = 2;

export default function OverviewBookingView({ setSelectedYear, selectedYear }) {
  // const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const currency = CurrencySwitch();

  const { dashboardData, earningsChartData, salesChart, productSoldCounts } = useSelector(
    (state) => ({
      dashboardData: state.dashboard.dashboardData,
      loading: state.dashboard.loading,
      earningsChartData: state.dashboard.earningsChart,
      salesChart: state.dashboard.salesChart,
      productSoldCounts: state.dashboard.productSoldCounts,
    })
  );

  console.log('productSoldCounts admin', productSoldCounts);

  // const { totalSalesCount, totalSalesValue, totalEarningsValue, totalEarningsCount } =
  //   dashboardData;
  // const averageSales = totalSalesValue / totalSalesCount;

  // const averageEarnings = totalEarningsValue / totalEarningsCount;

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
  };

  const totalSold =
    (productSoldCounts?.dashboardCounts?.partneshipSalesCount || 0) +
    (productSoldCounts?.dashboardCounts?.otherProductSalesCount || 0) +
    (productSoldCounts?.dashboardCounts?.schoolCourseSalesCount || 0) +
    (productSoldCounts?.dashboardCounts?.schoolSubscriptionSalesCount || 0);

  console.log(currency);
  return (
    <Grid container spacing={SPACING} disableEqualOverflow>
      <Grid>
        <Typography sx={{ fontWeight: '800', fontSize: mdUp ? '24px' : '15px' }}>
          Hi, Welcome Back 👋
        </Typography>
      </Grid>
      <Grid xs={12}>
        <ActiveUsers
          chart={{
            series: [
              {
                img: bag,
                label: 'Total Sales ',
                total: dashboardData?.totalSalesCount,
                background: '#fff3da',
                // 'linear-gradient(135deg, rgba(244, 153, 53, 0.8) 0%, rgba(238, 130, 54, 0.16) 100%)',
              },
              {
                img: kit,
                label: 'Total Sale Value',
                total: `${currency} ${dashboardData?.totalSalesValue}`,
                background: '#fdecde',
                // 'linear-gradient(135deg, rgba(255, 214, 102, 0.8) 0%, rgba(255, 171, 0, 0.16) 100%)',
              },
            ],
          }}
        />
      </Grid>
      {earningsChartData && salesChart && (
        <Grid item md={8} xs={12}>
          <SalesEarningChart
            selectedYear={selectedYear}
            handleYearChange={handleYearChange}
            title="Yearly Sales & Earnings"
            subheader={`sales & earnings of the year ${selectedYear}`}
            chart={{
              categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              series: [
                {
                  year: selectedYear,
                  data: [
                    {
                      name: 'Total Sales',
                      data: salesChart || [],
                    },
                    {
                      name: 'Total Earnings',
                      data: earningsChartData || [],
                    },
                  ],
                },
              ],
            }}
          />
        </Grid>
      )}

      <Grid md={4} xs={12}>
        <BalanceView
          percentageIncrement={dashboardData?.percentageIncrement}
          incrementBalance={dashboardData?.incrementBalance}
          partnership={productSoldCounts?.dashboardCounts?.partneshipSalesCount}
          totalOtherProduct={productSoldCounts?.dashboardCounts?.otherProductSalesCount}
          totalCourse={productSoldCounts?.dashboardCounts?.schoolCourseSalesCount}
          totalSchool={productSoldCounts?.dashboardCounts?.schoolSubscriptionSalesCount}
          totalSold={totalSold}
          chart={{
            series: [
              {
                img: bag,
                label: 'Total Sales Value',
                total: dashboardData?.totalSalesValue,
                background:
                  'linear-gradient(135deg, rgba(244, 153, 53, 0.8) 0%, rgba(238, 130, 54, 0.16) 100%)',
              },
              {
                img: kit,
                label: 'Total Earnings Value',
                total: dashboardData?.totalEarningsValue,
                background:
                  'linear-gradient(135deg, rgba(255, 214, 102, 0.8) 0%, rgba(255, 171, 0, 0.16) 100%)',
              },
            ],
          }}
        />
      </Grid>

      <Grid xs={12}>
        <PartnerCheckInWidgets
          chart={{
            series: [
              {
                label: 'Total Schools',
                total: dashboardData?.totalSchoolCount,
                img: stack3,
                link: paths.dashboard.school,
              },
              {
                label: 'Total Students',
                total: dashboardData?.totalStudentsCount,
                link: paths.dashboard.students,
                img: stack5,
              },
            ],
          }}
        />
      </Grid>
      <Grid xs={12}>
        <PartnerCheckInWidgets
          chart={{
            series: [
              {
                label: 'Total Courses',
                total: dashboardData?.totalCourseCount,
                img: stack6,
                link: paths.dashboard.course,
              },
              {
                label: 'Total Partners',
                total: dashboardData?.totalPartnerCount,
                link: paths.dashboard.partner,
                img: stack2,
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
}

OverviewBookingView.propTypes = {
  setSelectedYear: PropTypes.any,
  selectedYear: PropTypes.any,
};
