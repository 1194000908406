import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import {
  Tab,
  Card,
  Tabs,
  alpha,
  Paper,
  TableRow,
  TableCell,
  CircularProgress,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { isAfter } from 'src/utils/format-time';

import { getAllCourses } from 'src/server/api/courses';
import { getAllOtherProducts } from 'src/server/api/otherProduct';
// import { useGetRoles } from 'src/hooks/useHandleSessions';
// import OtherProducts from 'src/pages/Admin/otherProducts/otherProducts';

import { getAllLabModel } from 'src/server/api/labModel';
import { getAllPartnerCommisionV5 } from 'src/server/api/partner';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import SaleItemTableRow from 'src/sections/saleItem/saleItem-table-row';
import CourseTableToolbar from 'src/sections/schoolCourse/course-table-toolbar';
import OtherProductTableToolbar from 'src/sections/otherProducts/OtherProduct-toolbar';

import LabmodelTableRow from '../labModel/labModel-table-row';
import LabmodelTableToolbar from '../labModel/labModel-table-toolbar';
import OtherProductsTableRowSaleItem from '../otherProducts/otherProductsSaleItem';

// ----------------------------------------------------------------------

export default function SaleItemListView({ minimal, partnerCode, activeTab, setActiveTab }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt' });
  const otherProductsTable = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();
  const theme = useTheme();
  const identifier = 'sale_item';

  // lab model
  const [selectedSchool, setSelectedSchool] = useState('');

  const {
    loading,
    courses,
    otherProducts,
    labModelList,
    otherProductLoading,
    labModelLoading,
    partnerCommission,
  } = useSelector((state) => ({
    loading: state.Course.loading,
    courses: state.Course.course,
    otherProducts: state.otherProducts.allOtherProducts,
    labModelList: state.labModel.labModel,
    otherProductLoading: state.otherProducts.loading,
    labModelLoading: state.labModel.loading,
    partnerCommission: state.partner.partnerCommissions,
  }));

  console.log('partnerCommission', partnerCommission);

  console.log('labModelList', labModelList);

  const [searchText, setSearchText] = useState();
  const [sortDate, setSortDate] = useState({
    from: '',
    to: '',
  });

  const [tableData, setTableData] = useState([]);

  const [otherProductsTableData, setOtherProductsTableData] = useState([]);
  const [otherProductsSearchText, setOtherProductsSearchText] = useState('');
  const [otherProductsCategory, setOtherProductsCategory] = useState('');
  // const [otherProductsSort, setOtherProductsSort] = useState('');

  const [labModelsTableData, setLabModelTableData] = useState([]);

  const [labModelsearchText, setLbModelSearchText] = useState('');
  // console.log(tableData);

  const handleTabChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  const totalPage = courses?.total;
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);
  const TABLE_HEAD = [
    { id: 'images', label: '' },
    { id: 'title', label: 'Course' },
    { id: 'price', label: 'Price' },
    { id: 'direct_commission', label: 'Direct Commission' },
    { id: 'hierarchy_commission', label: 'Hierarchy Commission' },

    { id: 'view', label: '' },
    { id: 'share', label: '' },
  ];

  useEffect(() => {
    dispatch(getAllPartnerCommisionV5({ dispatch }));
  }, [dispatch]);

  useEffect(() => {
    const courseCredentials = {
      page: table.page + 1,
      limit: storedRowsPerPage || table.rowsPerPage,
      search: searchText || '',
      school: selectedSchool,
      sort: '',
      dispatch,
    };
    dispatch(getAllCourses(courseCredentials));
  }, [dispatch, searchText, selectedSchool, storedRowsPerPage, table.page, table.rowsPerPage]);

  useEffect(() => {
    setTableData(courses?.courses);
  }, [courses?.courses]);

  const otherProductsTotalPage = otherProducts?.total;

  const OTHER_PRODUCTS_TABLE_HEAD = [
    { id: 'title', label: 'Title' },
    { id: 'price', label: 'Price' },
    // { id: 'commission', label: 'Commission' },
    { id: 'direct_commission', label: 'Direct Commission' },
    { id: 'hierarchy_commission', label: 'Hierarchy Commission' },
    { id: 'created_on', label: 'Created On' },
    // { id: 'active', label: 'Active' },
    { id: 'action', label: 'Action' },
  ];

  const LAB_MODELS_TABLE_HEAD = [
    { id: 'title', label: 'Title' },
    { id: 'price', label: 'Price' },
    { id: 'direct_commission', label: 'Direct Commission' },
    { id: 'hierarchy_commission', label: 'Hierarchy Commission' },
    // { id: 'active', label: 'Active' },
    { id: 'action', label: 'Action' },
  ];

  // if (activeTab !== 'partnership') {
  //   OTHER_PRODUCTS_TABLE_HEAD.splice(1, 0, { id: 'category', label: 'Category' });
  // }

  useEffect(() => {
    const otherProductCredentials = {
      page: otherProductsTable.page + 1,
      search: otherProductsSearchText || '',
      limit: storedRowsPerPage || otherProductsTable.rowsPerPage,
      category: activeTab === 'partnership' ? 'PARTNER' : 'OTHER',
      filterValue: '',
      dispatch,
    };
    dispatch(getAllOtherProducts(otherProductCredentials));
  }, [
    dispatch,
    otherProductsSearchText,
    selectedSchool,
    storedRowsPerPage,
    otherProductsTable.page,
    otherProductsTable.rowsPerPage,
    otherProductsCategory,
    activeTab,
  ]);

  useEffect(() => {
    if (otherProducts && otherProducts.otherProducts) {
      setOtherProductsTableData(otherProducts.otherProducts);
    }
  }, [otherProducts]);

  // useEffect(()=>{
  //   dispatch(getAllLabPartnerCommision(credentials));
  // },[dispatch])

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: labModelsearchText || '',
      dispatch,
      limit: storedRowsPerPage || table.rowsPerPage,
      // school: selectedSchool?.value,
    };
    dispatch(getAllLabModel(credentials));
  }, [dispatch, searchText, storedRowsPerPage, table.page, table.rowsPerPage, labModelsearchText]);

  useEffect(() => {
    if (labModelList && labModelList.labModels) {
      setLabModelTableData(labModelList.labModels);
    }
  }, [labModelList]);

  const dateError = isAfter(sortDate.from, sortDate.to);

  const dataInPage = [];

  const canReset = false;

  const notFountLabmodel = (!labModelsTableData.length && canReset) || !labModelsTableData.length;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;
  const notFoundOther =
    (!otherProductsTableData?.length && canReset) || !otherProductsTableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);

      enqueueSnackbar('Delete success!');

      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );
  // const handleCopy = () => {
  //   enqueueSnackbar('Link copied!', { variant: 'success' });
  // };

  const TABS = [
    { value: 'courses', label: 'Courses' },
    { value: 'otherproduct', label: 'Other Products' },
    { value: 'partnership', label: 'Partnership' },
    { value: 'lab_meodels', label: 'Lab Models' },
  ];

  const partnerCommissions = partnerCommission?.partnerCommissions || [];
  const course = partnerCommissions.find((item) => item?.sale_item_type === 'course');
  const otherProduct = partnerCommissions.find((item) => item?.sale_item_type === 'other');
  const partnership = partnerCommissions.find((item) => item?.sale_item_type === 'partnership');
  const school = partnerCommissions.find((item) => item?.sale_item_type === 'school');

  const otherProductValue = activeTab === 'otherproduct' ? otherProduct : partnership;

  console.log('otherProduct', otherProduct);

  return (
    <Card>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          marginLeft: '20px',
          boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
        }}
      >
        {TABS.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label} iconPosition="end" />
        ))}
      </Tabs>

      {activeTab === 'courses' && (
        <>
          <CourseTableToolbar
            filters=""
            isSearchAvailable
            selectedSchool={selectedSchool}
            setSelectedSchool={setSelectedSchool}
            onFilters={(e, searchTextValue) => setSearchText(searchTextValue)}
            dateError={dateError}
            dateFilter={{ sate: sortDate, setSate: setSortDate }}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                <TableBody className="course-table" sx={{ minHeight: '500px' }}>
                  {(tableData || []).map((row) => (
                    <SaleItemTableRow
                      course={course}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                      partner_code={partnerCode}
                    />
                  ))}
                </TableBody>

                <TableNoData title="Course" notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>
          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </>
      )}
      {activeTab === 'otherproduct' && (
        <>
          <OtherProductTableToolbar
            searchValue={otherProductsSearchText}
            onSearch={setOtherProductsSearchText}
            filters={otherProductsCategory}
            onCategory={setOtherProductsCategory}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={OTHER_PRODUCTS_TABLE_HEAD}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />
                {otherProductLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 40 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {otherProductsTableData?.map((row) => (
                      <OtherProductsTableRowSaleItem
                        otherProduct={otherProductValue}
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                      />
                    ))}
                  </TableBody>
                )}
                {!otherProductsTableData?.length && <TableNoData notFound={notFoundOther} />}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={otherProductsTotalPage || 0}
            page={otherProductsTable.page}
            rowsPerPage={otherProductsTable.rowsPerPage}
            onPageChange={otherProductsTable.onChangePage}
            onRowsPerPageChange={otherProductsTable.onChangeRowsPerPage}
            dense={otherProductsTable.dense}
            onChangeDense={otherProductsTable.onChangeDense}
            identifier={identifier}
          />
        </>
      )}
      {activeTab === 'partnership' && (
        <>
          <OtherProductTableToolbar
            searchValue={otherProductsSearchText}
            onSearch={setOtherProductsSearchText}
            filters={otherProductsCategory}
            onCategory={setOtherProductsCategory}
            activeTab={activeTab}
          />
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={OTHER_PRODUCTS_TABLE_HEAD}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />
                {otherProductLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 40 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {otherProductsTableData?.map((row) => (
                      <OtherProductsTableRowSaleItem
                        otherProduct={otherProductValue}
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                        activeTab={activeTab}
                      />
                    ))}
                  </TableBody>
                )}
                {!otherProductsTableData?.length && <TableNoData notFound={notFoundOther} />}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={otherProductsTotalPage || 0}
            page={otherProductsTable.page}
            rowsPerPage={otherProductsTable.rowsPerPage}
            onPageChange={otherProductsTable.onChangePage}
            onRowsPerPageChange={otherProductsTable.onChangeRowsPerPage}
            dense={otherProductsTable.dense}
            onChangeDense={otherProductsTable.onChangeDense}
            identifier={identifier}
          />
        </>
      )}
      {activeTab === 'lab_meodels' && (
        <>
          <LabmodelTableToolbar
            searchValue={labModelsearchText}
            onSearch={setLbModelSearchText}
            // filters={otherProductsCategory}
            // onCategory={setOtherProductsCategory}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={LAB_MODELS_TABLE_HEAD}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />
                {labModelLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 40 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {labModelsTableData?.map((row) => (
                      <LabmodelTableRow
                        school={school}
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                      />
                    ))}
                  </TableBody>
                )}
                {!labModelsTableData?.length && <TableNoData notFound={notFountLabmodel} />}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={otherProductsTotalPage || 0}
            page={otherProductsTable.page}
            rowsPerPage={otherProductsTable.rowsPerPage}
            onPageChange={otherProductsTable.onChangePage}
            onRowsPerPageChange={otherProductsTable.onChangeRowsPerPage}
            dense={otherProductsTable.dense}
            onChangeDense={otherProductsTable.onChangeDense}
            identifier={identifier}
          />
        </>
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </Card>
  );
}

SaleItemListView.propTypes = {
  minimal: PropTypes.any,
  partnerCode: PropTypes.any,
  activeTab: PropTypes.any,
  setActiveTab: PropTypes.any,
};
