import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createStudentExam = createAsyncThunk(
  'student-exam/create',
  async ({ dispatch, navigate }) => {
    try {
      const URL = `/student-exam/admin/new`;
      const response = await post(URL);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Exam Created successfully!!' }));
        const getSchoolUserProfile = await get('/school-user');
        useSetEncryptLocalData('isOnBoarded', getSchoolUserProfile?.is_onboarded);
        // if (getSchoolUserProfile?.is_onboarded) {
        navigate(paths.dashboard.root);
        return response;
        // }
      }
      return '';
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// export const getAllStudentExam = createAsyncThunk(
//   'student-exam/list',
//   async ({ page, limit, date, sort, student, status, course, dispatch }) => {
//     try {
//       const response = await get(
//         `/student-exam/admin/all?page=${page || 1}&limit=${limit || 10}&student=${
//           student || ''
//         }&status=${status || ''}&course=${course ?? ''}&dateFrom=${date.from || ''}&dateTo=${
//           date.to || ''
//         }&sort=${sort || ''}`
//       );
//       // console.log(response);
//       if (response) {
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );
export const getAllStudentExam = createAsyncThunk(
  'student-exam/list',
  async ({ page, limit, date, sort, student, status, course, dispatch }) => {
    try {
      const response = await get(
        `/student-exam/admin/all?page=${page || 1}&limit=${limit || 10}&student=${
          student || ''
        }&sort=${sort || ''}&status=${status || ''}&dateTo=${date.to || ''}&course=${course || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllStudentExamList = createAsyncThunk(
  'student-exam/list',
  async ({ page, search, limit, sort, dispatch }) => {
    try {
      const URL = `/student-exam/admin/all?page=${page || 1}&limit=${limit || 10}&sort=${
        sort || ''
      }`;

      const URL_DROPDOWN = `/student-exam//admin/all?search=${search && search}`;

      const response = await get(page ? URL : URL_DROPDOWN);
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateStudentExamDetails = createAsyncThunk(
  'student-exam/update',
  async ({ state, StudentExamId, dispatch, navigate, handleClose }) => {
    try {
      const URL = `/student-exam/admin/${StudentExamId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        if (navigate) {
          navigate();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'School course Item updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteStudentExam = createAsyncThunk(
  'student-exame/delete',
  async ({ StudentExamId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/student-exam/admin/${StudentExamId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(
          activeSnack({ type: 'success', message: 'School course Item deleted Successfully' })
        );
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// student api
export const registerStudentExam = createAsyncThunk(
  'student-exam/register',
  async ({ enqueueSnackbar, state, navigate }) => {
    try {
      const URL = `student-exam/student/new`;
      const response = await post(URL, state);
      if (response) {
        enqueueSnackbar('Exam Registered successfully!');
        navigate(paths.auth.exams, { state: { examId: '0' } });
        return response;
      }
      return '';
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const submitStudentExamInput = createAsyncThunk(
  'student-exam/input/submit',
  async ({ enqueueSnackbar, state, navigate, resetExam }) => {
    try {
      const URL = `student-exam-input/student/new`;
      const response = await post(URL, state);

      if (response) {
        navigate(paths?.auth.exams_over(response?.[0]?.student_exam));
        enqueueSnackbar('Exam Submitted successfully!');
        resetExam(false);
        return response;
      }
      return '';
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });

      throw error?.response?.data?.message;
    }
  }
);

export const submitStudentExamInputV5 = createAsyncThunk(
  'student-exam/input/submitV5',
  async ({ enqueueSnackbar, state, navigate, resetExam }) => {
    try {
      const URL = `student-exam-input/v5/student/new`;
      const response = await post(URL, state);

      if (response) {
        navigate(paths?.auth.exams_over(response?.[0]?.student_exam));
        enqueueSnackbar('Exam Submitted successfully!');
        resetExam(false);
        return response;
      }
      return '';
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });

      throw error?.response?.data?.message;
    }
  }
);

export const getAllAttendedStudentExam = createAsyncThunk(
  'student-exam/attended',
  async ({ page, search, limit, dispatch }) => {
    try {
      const URL = `student-exam/student/attended/exams?page=${page || 1}&search=${
        search || ''
      }&limit=${limit || 10}`;

      const URL_DROPDOWN = `student-exam/student/attended/exams?search=${search && search}`;

      const response = await get(page ? URL : URL_DROPDOWN);
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getStudentExamDetails = createAsyncThunk(
  'student-exam/single',
  async ({ studentExamId, dispatch }) => {
    try {
      const response = await get(`/student-exam/admin/${studentExamId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
