import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createLmsExamCertificate = createAsyncThunk(
  'certificate/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/student-certificate/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Certificate Created successfully!!' }));
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllLmsExamCertificate = createAsyncThunk(
  'certificate/list',
  async ({ page, search, limit, student, exam, course, dispatch }) => {
    try {
      const response = await get(
        `/student-certificate/admin/all?page=${page || 1}&search=${search || ''}&limit=${
          limit || 5
        }&student=${student || ''}&exam=${exam || ''}&course=${course || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getLmsExamCertificateDetails = createAsyncThunk(
  'certificate/single',
  async ({ LmsExamCertificateId, dispatch }) => {
    try {
      const response = await get(`/student-certificate/admin/${LmsExamCertificateId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateLmsExamCertificateDetails = createAsyncThunk(
  'certificate/update',
  async ({ state, certificateId, dispatch, handleClose }) => {
    try {
      const URL = `/student-certificate/admin/${certificateId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          limit: sessionStorage.getItem(`rowsPerPage_admin-studentCertificate`),
          dispatch,
        };
        dispatch(getAllLmsExamCertificate(cred));
        dispatch(activeSnack({ type: 'success', message: 'Certificate updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteLmsExamCertificate = createAsyncThunk(
  'certificate/delete',
  async ({ LmsExamCertificateId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/student-certificate/admin/${LmsExamCertificateId}/`);
      if (response) {
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        dispatch(activeSnack({ type: 'success', message: 'Certificate deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createCertificate = createAsyncThunk(
  'certificate/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/student-certificate/admin/manual`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          limit: sessionStorage.getItem(`rowsPerPage_admin-studentCertificate`),
          dispatch,
        };
        dispatch(getAllLmsExamCertificate(cred));
        dispatch(activeSnack({ type: 'success', message: 'Certificate Created successfully!!' }));
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createCertificateV5 = createAsyncThunk(
  'certificate/createV5',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/student-certificate/v5/admin/manual`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          limit: sessionStorage.getItem(`rowsPerPage_admin-studentCertificate`),
          dispatch,
        };
        dispatch(getAllLmsExamCertificate(cred));
        dispatch(activeSnack({ type: 'success', message: 'Certificate Created successfully!!' }));
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const bulkUploadCertificate = createAsyncThunk(
  'certificate/bulk',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/student-certificate/admin/bulk`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          limit: sessionStorage.getItem(`rowsPerPage_admin-studentCertificate`),
          dispatch,
        };
        dispatch(getAllLmsExamCertificate(cred));
        dispatch(
          activeSnack({
            type: 'warning',
            duration: 10000,
            message:
              `${response?.message} Refresh list after some times` ||
              'Certificate Created successfully!!',
          })
        );
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const bulkUploadCertificateV5 = createAsyncThunk(
  'certificate/bulkV5',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/student-certificate/v5/admin/bulk`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          limit: sessionStorage.getItem(`rowsPerPage_admin-studentCertificate`),
          dispatch,
        };
        dispatch(getAllLmsExamCertificate(cred));
        dispatch(
          activeSnack({
            type: 'warning',
            duration: 10000,
            message:
              `${response?.message} Refresh list after some times` ||
              'Certificate Created successfully!!',
          })
        );
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
