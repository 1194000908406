import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, post } from './http';
import { activeSnack } from '../store/common';

export const getAllEarning = createAsyncThunk(
  'earning/list',
  async ({ page, sort, date, limit, dispatch, partner_type, partner }) => {
    try {
      const response = await get(
        `/earning/admin/all?page=${page || 1}&limit=${limit || 5}&sort=${sort || ''}&dateFrom=${
          date?.from || ''
        }&dateTo=${date?.to || ''}&partner_type=${partner_type || ''}&partner=${partner || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllEarningV3 = createAsyncThunk(
  'earning/list/v3',
  async ({ page, sort, date, limit, dispatch, partner_type, partner, state }) => {
    try {
      console.log('state', state);

      const response = await post(
        `/earning/v3/admin/all?page=${page || 1}&limit=${limit || 5}&sort=${sort || ''}&dateFrom=${
          date?.from || ''
        }&dateTo=${date?.to || ''}&partner_type=${partner_type || ''}&partner=${partner || ''}`,
        state
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSubPartnerEarnings = createAsyncThunk(
  'subPartner-earning/list',
  async ({ page, sort, date, limit, dispatch, partner_type, partner }) => {
    try {
      const response = await get(
        `/earning/v2/sub-partner/all?page=${page || 1}&limit=${limit || 5}&sort=${sort || ''}&dateFrom=${
          date?.from || ''
        }&dateTo=${date?.to || ''}&partner_type=${partner_type || ''}&partner=${partner || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSinglePartnerEarning = createAsyncThunk(
  'earning/list',
  async ({ page, sort, date, limit, dispatch, partner }) => {
    try {
      const response = await get(
        `/earning/country-head/all/${partner}?page=${page || 1}&limit=${limit || 5}&sort=${
          sort && sort
        }&dateFrom=${date.from || ''}&dateTo=${date.to || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
