import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createTaluk = createAsyncThunk(
  'taluk/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/taluk/admin/new`;

      const response = await post(URL, state);
      if (response) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'Taluk created successfully' }));
        dispatch(getAllTaluk({ dispatch, limit: sessionStorage.getItem(`rowsPerPage_taluk`) }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllTaluk = createAsyncThunk(
  'taluk/list',
  async ({ page, search, country, state, district, dispatch, limit }) => {
    try {
      const response = await get(
        `taluk/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&country=${country || ''}&state=${state || ''}&district=${district || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// export const getCountryDetails = createAsyncThunk(
//   'country/single',
//   async ({ countryId, dispatch }) => {
//     try {
//       console.log('ID:', countryId);
//       const response = await get(`/country/admin/${countryId}`);
//       if (response) {
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

export const updateTalukDetails = createAsyncThunk(
  'country/update',
  async ({ state, districtId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/taluk/admin/${districtId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(getAllTaluk({ dispatch, limit: sessionStorage.getItem(`rowsPerPage_taluk`) }));
        dispatch(activeSnack({ type: 'success', message: 'taluk updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteTaluk = createAsyncThunk('taluk/delete', async ({ talukId, dispatch }) => {
  try {
    const response = await del(`/taluk/admin/${talukId}/`);
    if (response) {
      dispatch(getAllTaluk({ dispatch, limit: sessionStorage.getItem(`rowsPerPage_taluk`) }));
      dispatch(activeSnack({ type: 'success', message: 'taluk deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

// export const getAllCountries = createAsyncThunk(
//   'country/list',
//   async ({ page, search, dispatch, limit }) => {
//     try {
//       const response = await get(
//         `/country/all?page=${page && page}&search=${search && search}&limit=${limit && limit}`
//       );
//       console.log(response);
//       if (response) {
//         return {
//           // response,
//           countries: response?.countries,
//           total: response?.total,
//           page: response?.page,
//         };
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );
