import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createDistrict = createAsyncThunk(
  'district/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `district/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        dispatch(
          getAllDistrict({ dispatch, limit: sessionStorage.getItem(`rowsPerPage_district`) })
        );
        dispatch(activeSnack({ type: 'success', message: 'district created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllDistrict = createAsyncThunk(
  'district/list',
  async ({ page, search, state, country, dispatch, limit }) => {
    try {
      const response = await get(
        `district/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&country=${country || ''}&state=${state || ''}`
      );
      console.log('response states', response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// export const getCountryDetails = createAsyncThunk(
//   'country/single',
//   async ({ countryId, dispatch }) => {
//     try {
//       console.log('ID:', countryId);
//       const response = await get(`/country/admin/${countryId}`);
//       if (response) {
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

export const updateDistrictDetails = createAsyncThunk(
  'country/update',
  async ({ state, districtId, dispatch, handleClose }) => {
    try {
      const URL = `/district/admin/${districtId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(
          getAllDistrict({ dispatch, limit: sessionStorage.getItem(`rowsPerPage_district`) })
        );
        dispatch(activeSnack({ type: 'success', message: 'District updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteDistrict = createAsyncThunk(
  'district/delete',
  async ({ districtId, dispatch }) => {
    try {
      const response = await del(`/district/admin/${districtId}/`);
      if (response) {
        dispatch(
          getAllDistrict({ dispatch, limit: sessionStorage.getItem(`rowsPerPage_district`) })
        );
        dispatch(activeSnack({ type: 'success', message: 'district deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// export const getAllCountries = createAsyncThunk(
//   'country/list',
//   async ({ page, search, dispatch, limit }) => {
//     try {
//       const response = await get(
//         `/country/all?page=${page && page}&search=${search && search}&limit=${limit && limit}`
//       );
//       console.log(response);
//       if (response) {
//         return {
//           // response,
//           countries: response?.countries,
//           total: response?.total,
//           page: response?.page,
//         };
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );
