import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack';
// import { useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// import IconButton from '@mui/material/IconButton';
// import { Box, Autocomplete } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

// import { getAllSchool } from 'src/server/api/school';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function LabmodelTableToolbar({
  filters,
  onFilters,
  searchValue,
  onSearch,
  isSearchAvailable,
  // dateFilter,
  // saleStatus,
  // setSaleStatus,
  // selectedSchool,
  setSelectedSchool,
}) {
  console.log('labmodel table row');

  const popover = usePopover();
  // const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();
  // const { sate, setSate } = dateFilter;
  // LabModel
  // const [searchValue, setSearchValue] = useState('');
  // const handleFilterName = useCallback(
  //   (event) => {
  //     onFilters('name', event.target.value);
  //   },
  //   [onFilters]
  // );
  // console.log(onFilters);
  const { schoolList } = useSelector((state) => ({
    loading: state.school.loading,
    schoolList: state.school.school,
  }));
  console.log(schoolList);
  // const optionSchool = [
  //   { label: 'All', value: '' },
  //   ...(schoolList?.schools || []).map((results) => ({
  //     label: results?.name,
  //     value: results?._id,
  //   })),
  // ];
  // const handleAutocompleteChange = (_, newValue) => {
  //   setSelectedSchool(newValue?.value || '');
  // };
  // const handleTextFieldChange = (event) => {
  //   setSearchValue(event.target.value);
  // };
  // useEffect(() => {
  //   const credentials = {
  //     page: 1,
  //     search: searchValue,
  //     limit: 10,
  //     partner: '',
  //     lab_model: '',
  //     dispatch,
  //   };
  //   console.log('Credentials:', credentials);
  //   dispatch(getAllSchool(credentials));
  // }, [dispatch, searchValue]);

  // const handleFilterService = useCallback(
  //   (event) => {
  //     onFilters(
  //       'service',
  //       typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
  //     );
  //   },
  //   [onFilters]
  // );

  // const handleFilterStartDate = useCallback(
  //   (newValue) => {
  //     onFilters('startDate', newValue);
  //   },
  //   [onFilters]
  // );

  // const handleFilterEndDate = useCallback(
  //   (newValue) => {
  //     onFilters('endDate', newValue);
  //   },
  //   [onFilters]
  // );
  // const statusOptions = [
  //   { label: 'Pending', value: 'pending' },
  //   { label: 'Approved', value: 'approved' },
  // ];
  // const handleSaleStatusChange = (event) => {
  //   setSaleStatus(event.target.value);
  // };
  // const handleCopy = () => {
  //   // You can add any additional logic here when the link is copied
  //   console.log('Link copied to clipboard');
  //   enqueueSnackbar('Link copied!', { variant: 'success' });
  // };
  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        justifyContent="end"
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack
          lg={3}
          direction="row"
          alignItems="center"
          spacing={2}
          flexGrow={1}
          sx={{ width: 1 }}
        >
          <TextField
            size="small"
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            placeholder="Search For a lab Model"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          {/* <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton> */}
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  );
}

LabmodelTableToolbar.propTypes = {
  isSearchAvailable: PropTypes.bool,
  filters: PropTypes.object,
  // dateFilter: PropTypes.object,
  onFilters: PropTypes.func,
  // saleStatus: PropTypes.any,
  // setSaleStatus: PropTypes.any,
  setSelectedSchool: PropTypes.any,
  searchValue: PropTypes.any,
  onSearch: PropTypes.any,
  // selectedSchool: PropTypes.any,
};
