import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import PreviewIcon from '@mui/icons-material/Visibility';
import { Stack, Switch, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { frontEndUrl } from 'src/server/api/http';
import { updateOtherProductDetails } from 'src/server/api/otherProduct';

import Label from 'src/components/label/label';

// ----------------------------------------------------------------------

export default function OtherProductsTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  loading,
  colSpan,
  handleEditClick,
  index,
  page,
  rowsPerPage,
}) {
  const {
    title,
    category,
    offer_price,
    price,
    base_price,
    // partner_commission_amount,
    // partner_commission_percentage,
    _id,
    createdAt,
    is_active,
  } = row;
  const { partnerDetails } = useSelector((state) => ({
    loading: state.Course.loading,
    partnerDetails: state.partner.profile,
  }));

  const { role } = useGetRoles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleToggleActive = (e, value) => {
    const UpdateCredentials = {
      state: {
        ...row,
        is_active: value,
      },
      otherProductId: row?._id,
      dispatch,
    };

    dispatch(updateOtherProductDetails(UpdateCredentials));
  };

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };

  const formattedDate = createdAt ? format(new Date(createdAt), 'dd MMM yyyy') : '';
  const formattedTitle = title?.replace(/\s+/g, '-');

  const priceDisplay =
    price === 0 ? (
      <Label variant="soft" color="success" sx={{ fontWeight: 'bold' }}>
        Free
      </Label>
    ) : (
      <Stack direction="row" alignItems="center" spacing={1}>
        {offer_price > 0 && (
          <Typography
            variant="caption"
            sx={{ color: 'text.secondary', textDecoration: 'line-through' }}
          >
            ₹{price}
          </Typography>
        )}
        <Typography variant="caption" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
          ₹{offer_price > 0 ? offer_price : price}
        </Typography>
      </Stack>
    );

  return (
    <TableRow hover selected={selected}>
      <TableCell>
        <Typography variant="body2" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
          {title}
        </Typography>
      </TableCell>

      <TableCell align="start">
        <Label
          variant="soft"
          color={category === 'OTHER' ? 'secondary' : 'success'}
          sx={{ borderRadius: 1, px: 2, textTransform: 'capitalize' }}
        >
          {category}
        </Label>
      </TableCell>
      <TableCell align="start">₹{base_price}</TableCell>
      <TableCell align="start">{priceDisplay}</TableCell>

      {/* <TableCell align="left">
        <Typography variant="caption" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
          ₹{partner_commission_amount}{' '}
          <Typography component="span" variant="caption">
            ({partner_commission_percentage?.toFixed(2)}%)
          </Typography>
        </Typography>
      </TableCell> */}

      <TableCell align="left">
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {formattedDate}
        </Typography>
      </TableCell>
      {role === 'super_admin' && (
        <TableCell align="left">
          <Switch checked={is_active} onChange={handleToggleActive} />
          <Typography
            sx={{
              ...(!is_active && {
                ml: 2,
              }),
              fontSize: '0.795rem',
            }}
          >
            {is_active ? 'Published' : 'Draft'}
          </Typography>
        </TableCell>
      )}
      <TableCell align="left">
        <Stack direction="row" spacing={1}>
          <Link
            target="_blank"
            to={
              role === 'super_admin'
                ? paths.public.other_product_view(formattedTitle)
                : paths.public.other_product_view(
                    `${formattedTitle}?ref_id=${partnerDetails?.findPartner?.partner_code}`
                  )
            }
          >
            <Label
              variant="soft"
              sx={{
                backgroundColor: '#EAF4FF',
                color: 'blue',
                textTransform: 'none',
                p: 1,
                cursor: 'pointer',
              }}
            >
              <PreviewIcon
                sx={{
                  fontSize: '17px',
                }}
              />
            </Label>
          </Link>

          <CopyToClipboard
            text={
              role === 'super_admin'
                ? `${frontEndUrl}${paths.public.other_product_view(formattedTitle)}`
                : `${frontEndUrl}${paths.public.other_product_view(formattedTitle)}?ref_id=${partnerDetails?.findPartner?.partner_code}`
            }
            onCopy={handleCopy}
          >
            <Label
              variant="soft"
              sx={{
                color: 'blue',
                textTransform: 'none',
                p: 1,
                cursor: 'pointer',
              }}
            >
              <ContentCopyIcon
                sx={{
                  fontSize: '17px',
                }}
              />
            </Label>
          </CopyToClipboard>
          {role === 'super_admin' && (
            <Link to={paths.dashboard.update_otherproduct(_id)}>
              <Label
                variant="soft"
                sx={{
                  backgroundColor: '#EAF4FF',
                  color: 'blue',
                  textTransform: 'none',
                  p: 1,
                  cursor: 'pointer',
                }}
              >
                Edit
              </Label>
            </Link>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

OtherProductsTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  colSpan: PropTypes.number,
  handleEditClick: PropTypes.func,
  index: PropTypes.any,
  rowsPerPage: PropTypes.any,
  page: PropTypes.any,
};
