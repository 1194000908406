import { createSlice } from '@reduxjs/toolkit';

import {
  getAllEarning,
  getAllEarningV3,
  getSubPartnerEarnings,
  getSinglePartnerEarning,
} from '../api/earning';

export const countrySlice = createSlice({
  name: 'earning',
  initialState: {
    loading: false,
    error: {},
    earning: {},
    earningV3: {},
    subPartnerEarning: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all earning
    [getAllEarning.pending]: (state) => {
      state.loading = true;
    },
    [getAllEarning.fulfilled]: (state, action) => {
      state.loading = false;
      state.earning = action.payload;
      state.error = {};
    },
    [getAllEarning.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all earning v3
    [getAllEarningV3.pending]: (state) => {
      state.loading = true;
    },
    [getAllEarningV3.fulfilled]: (state, action) => {
      state.loading = false;
      state.earningV3 = action.payload;
      state.error = {};
    },
    [getAllEarningV3.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getSubPartnerEarnings.pending]: (state) => {
      state.loading = true;
    },
    [getSubPartnerEarnings.fulfilled]: (state, action) => {
      state.loading = false;
      state.subPartnerEarning = action.payload;
      state.error = {};
    },
    [getSubPartnerEarnings.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getSinglePartnerEarning.pending]: (state) => {
      state.loading = true;
    },
    [getSinglePartnerEarning.fulfilled]: (state, action) => {
      state.loading = false;
      state.earning = action.payload;
      state.error = {};
    },
    [getSinglePartnerEarning.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = countrySlice.actions;

export default countrySlice.reducer;
