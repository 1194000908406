import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies

import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
// import CopyToClipboard from 'react-copy-to-clipboard';

import { isNaN } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ShareIcon from '@mui/icons-material/Share';
import ListItemText from '@mui/material/ListItemText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Stack, Button, IconButton, Typography, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { frontEndUrl } from 'src/server/api/http';
import noImage from 'src/assets/other/noImage.png';

// ----------------------------------------------------------------------

export default function SaleItemTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  loading,
  colSpan,
  partner_code,
  course,
}) {
  const navigate = useNavigate();
  const { title, images, price, offer_price, _id, base_price } = row;

  const directCommission = course?.direct_commission;
  const hierarchyCommission = course?.hierarchy_commission;

  const priceValue = offer_price || price;
  const value = priceValue - base_price;
  const directCommissionValue = (value * directCommission) / 100;

  const hierarchyCommissionValue = (value * hierarchyCommission) / 100;

  // const { profile } = useSelector((state) => ({
  //   profile: state.partner.profile,
  // }));

  const handleEditClick = (id) => {
    navigate(paths.public.course_view(id));
  };

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  return (
    <>
      {loading ? (
        <TableRow>
          <TableCell colSpan={colSpan + 1} align="center">
            <CircularProgress size="16px" />
          </TableCell>
        </TableRow>
      ) : (
        <TableRow hover selected={selected}>
          <TableCell>
            {images.length > 0 && images[0]?.url.length > 0 ? (
              <img
                src={images[0]?.url}
                alt="Course"
                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
              />
            ) : (
              <img
                src={noImage}
                alt="Default "
                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
              />
            )}
          </TableCell>
          <TableCell>
            <ListItemText
              primary={title}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>
          <TableCell>
            <Stack direction="row" alignItems="center" spacing={1}>
              {offer_price > 0 && (
                <Typography
                  variant="caption"
                  sx={{ color: 'text.secondary', textDecoration: 'line-through' }}
                >
                  ₹{price}
                </Typography>
              )}
              <Typography variant="caption" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                ₹{offer_price > 0 ? offer_price : price}
              </Typography>
            </Stack>
          </TableCell>
          <TableCell>
            {isNaN(directCommissionValue) ? 'N/A' : `₹${directCommissionValue.toFixed(2)}`}
          </TableCell>{' '}
          <TableCell>
            {isNaN(hierarchyCommissionValue) ? 'N/A' : `₹${hierarchyCommissionValue.toFixed(2)}`}
          </TableCell>
          <TableCell>
            <IconButton onClick={() => handleEditClick(_id)}>
              <VisibilityIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            <CopyToClipboard
              text={`${frontEndUrl}${paths.public.course_view(_id)}?ref_id=${partner_code}`}
              onCopy={handleCopy}
            >
              <Button
                variant="text"
                color="inherit"
                sx={{ color: 'gray' }}
                size="small"
                startIcon={<ShareIcon />}
                style={{ whiteSpace: 'nowrap' }}
              >
                Share
              </Button>
            </CopyToClipboard>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

SaleItemTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  colSpan: PropTypes.number,
  partner_code: PropTypes.any,
  course: PropTypes.any,
};
