import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { Tab, Tabs, alpha } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { clearOtherProductData } from 'src/server/store/otherProduct';
import { getAllCounts, getAllOtherProducts } from 'src/server/api/otherProduct';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
// import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import OtherProductsTableRow from 'src/sections/otherProducts/otherProducts';
import OtherProductTableToolbar from 'src/sections/otherProducts/OtherProduct-toolbar';

export default function OtherProducts() {
  // const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const settings = useSettingsContext();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();
  const { role } = useGetRoles();

  const { allCounts, allOtherProducts } = useSelector((state) => ({
    loading: state.otherProducts.loading,
    allOtherProducts: state.otherProducts.allOtherProducts,
    allCounts: state.otherProducts.allCounts,
  }));

  const allcounts = allCounts.counts;

  const identifier = 'admin-otherproducts';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const totalPage = allOtherProducts?.total;

  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [category, setCategory] = useState('');
  const [sort, setSort] = useState('');

  const TABLE_HEAD = [
    { id: 'title', label: 'Title' },
    { id: 'category', label: 'Category' },
    { id: 'base_rice', label: 'Base Price' },

    { id: 'price', label: 'Price' },
    // { id: 'commission', label: 'Commission' },
    { id: 'created_on', label: 'Created On' },
    { id: 'status', label: 'Status' },

    { id: 'action', label: 'Action' },
  ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: searchText || '',
      limit: storedRowsPerPage || table.rowsPerPage,
      category: category.toLocaleUpperCase(),
      filterValue: sort.toLocaleUpperCase(),
      dispatch,
    };
    dispatch(getAllOtherProducts(credentials));
  }, [dispatch, table.page, sort, table.rowsPerPage, category, storedRowsPerPage, searchText]);

  // Get all counts for each tab
  useEffect(() => {
    if (role === 'super_admin') {
      const credentials = {
        search: searchText || '',
        category: category.toLocaleUpperCase(),
        filterValue: sort.toLocaleUpperCase(),
        dispatch,
      };
      dispatch(getAllCounts(credentials));
    }
  }, [dispatch, searchText, role, category, sort, allOtherProducts]);

  // Update table data
  useEffect(() => {
    if (allOtherProducts && allOtherProducts.otherProducts) {
      setTableData(allOtherProducts.otherProducts);
    }
  }, [allOtherProducts]);

  const notFound = !tableData?.length;

  // Define tabs with dynamic counts
  const TABS = [
    { value: '', label: 'All', color: 'info', count: allcounts?.allOtherProducts || 0 },
    {
      value: 'published',
      label: 'Published',
      color: 'success',
      count: allcounts?.publishedOtherProducts || 0,
    },
    {
      value: 'drafted',
      label: 'Draft',
      color: 'info',
      count: allcounts?.draftedOtherProducts || 0,
    },
  ];

  // const handleFilters = (filters) => {
  //   if (filters.name !== undefined) {
  //     setSearchText(filters.name);
  //   }
  //   if (filters.partnerType !== undefined) {
  //     setCategory(filters.partnerType);
  //   }
  // };

  const handleTabChange = (e, newValue) => {
    setSort(newValue);
    table.setPage(0);
  };

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleCreate = () => {
    dispatch(clearOtherProductData());
    router.push(paths.dashboard.create_otherproduct);
  };

  return (
    <>
      <MetaHelmet title="Other Products" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        {role === 'super_admin' && (
          <CustomBreadcrumbs
            heading="Other Products"
            links={[{ name: 'Dashboard', href: paths.dashboard.root }, { name: 'Other Products' }]}
            action={
              <Stack spacing={2} flexDirection="row" alignItems="center">
                {/* <Link to={paths.dashboard.create_otherproduct}> */}
                <Button
                  onClick={handleCreate}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                >
                  Create
                </Button>
                {/* </Link> */}
              </Stack>
            }
            sx={{ mb: { xs: 3, md: 5 } }}
          />
        )}

        <Card>
          {role === 'super_admin' && (
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Tabs
                value={sort}
                onChange={handleTabChange}
                sx={{
                  px: 2.5,
                  boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {TABS?.map((tab) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                    iconPosition="end"
                    icon={
                      <Label
                        variant={
                          ((tab.value === 'all' || tab.value === sort) && 'filled') || 'soft'
                        }
                        color={tab.color}
                      >
                        {tab.count}
                      </Label>
                    }
                  />
                ))}
              </Tabs>
            </Stack>
          )}

          <OtherProductTableToolbar
            searchValue={searchText}
            onSearch={setSearchText}
            filters={category}
            onCategory={setCategory}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />
                <TableBody>
                  {tableData.map((row) => (
                    <OtherProductsTableRow
                      key={row._id}
                      row={row}
                      selected={table.selected.includes(row._id)}
                      onSelectRow={() => table.onSelectRow(row._id)}
                      onViewRow={() => handleViewRow(row._id)}
                      onEditRow={() => handleEditRow(row._id)}
                    />
                  ))}
                </TableBody>
                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure you want to delete ${table.selected.length} items?`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              // handleDeleteRows logic
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
