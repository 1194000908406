import { useSnackbar } from 'notistack';
import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FlagIcon from '@mui/icons-material/Flag';
import GroupIcon from '@mui/icons-material/Group';
import ClassIcon from '@mui/icons-material/Class';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import RedeemIcon from '@mui/icons-material/Redeem';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Person3Icon from '@mui/icons-material/Person3';
import PortraitIcon from '@mui/icons-material/Portrait';
import HandshakeIcon from '@mui/icons-material/Handshake';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles, useGetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { getSidebarItemCountData } from 'src/server/api/dashboard';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// ----------------------------------------------------------------------

export function useNavData() {
  const { role } = useGetRoles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { data: partnerType } = useGetEncryptLocalData('partnerType');
  const { profile, sidebarCounts } = useSelector((state) => ({
    profile: state.partner.profile,
    sidebarCounts: state.dashboard.sidebarCounts,
  }));

  const counts = sidebarCounts?.dashboardCounts;

  useEffect(() => {
    const credentials = {
      userType: role,
      enqueueSnackbar,
    };
    dispatch(getSidebarItemCountData(credentials));
  }, [dispatch, enqueueSnackbar, role]);

  const type = partnerType || profile?.type;

  // totalPartnerCount(pin):0
  // totalPartnerTypesCount(pin):3
  // totalPartnerCommissionsCount(pin):12
  // totalLabModelsCount(pin):4
  // totalEarningsCount(pin):2
  // totalSchoolCount(pin):12
  // totalSchoolUserCount(pin):12
  // totalSupportMaterialCount(pin):5
  // totalStudentsCount(pin):12
  // totalCourseCount(pin):5
  // totalExamCount(pin):4
  // totalStudentCertificateCount(pin):2
  // totalCountriesCount(pin):4

  //   totalEarningsCount: 2
  // totalSalesCount: 6
  // totalSchoolCount: 2

  // sidebar data block
  const adminSideBarData = useMemo(
    () => [
      {
        subheader: 'Dashboard',
        items: [
          { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: <DashboardIcon /> },
          {
            title: 'Sales',
            path: paths.dashboard.sale,
            icon: icon('ic_order'),
            info: <Label color="warning">{counts?.totalSalesCount}</Label>,
          },
        ],
      },
      {
        subheader: 'PARTNER',
        items: [
          {
            title: 'Partners',
            path: paths.dashboard.partner,
            icon: icon('ic_user'),
            info: <Label color="warning">{counts?.totalPartnerCount}</Label>,
          },
          {
            title: 'Partner Types',
            path: paths.dashboard.partner_type,
            icon: <GroupIcon />,
            info: <Label color="warning">{counts?.totalPartnerTypesCount}</Label>,
          },
          {
            title: 'Partner Income',
            path: paths.dashboard.partner_commission,
            icon: <ChecklistRtlIcon />,
            // info: <Label color="warning">{counts?.totalPartnerCommissionsCount}</Label>,
          },
          {
            title: 'Earnings',
            path: paths.dashboard.earnings,
            icon: icon('ic_invoice'),
            info: <Label color="warning">{counts?.totalEarningsCount}</Label>,
          },
        ],
      },
      {
        subheader: 'LMS',
        items: [
          {
            title: 'Lab Models ',
            path: paths.dashboard.labModel,
            icon: <ModelTrainingIcon />,
            info: <Label color="warning">{counts?.totalLabModelsCount}</Label>,
          },
          {
            title: 'Schools',
            path: paths.dashboard.school,
            icon: <SchoolIcon />,
            info: <Label color="warning">{counts?.totalSchoolCount}</Label>,
          },
          {
            title: 'Classes',
            path: paths.dashboard.class,
            icon: <ClassIcon />,
            info: <Label color="warning">{counts?.totalClassCount || 0}</Label>,
          },
          {
            title: 'School Users',
            path: paths.dashboard.school_user,
            icon: <PortraitIcon />,
            info: <Label color="warning">{counts?.totalSchoolUserCount}</Label>,
          },
          // {
          //   title: 'Support Material',
          //   path: paths.dashboard.supportMaterial,
          //   icon: icon('ic_booking'),
          //   info: <Label color="warning">{counts?.totalSupportMaterialCount}</Label>,
          // },
          {
            title: 'Course',
            path: paths.dashboard.course,
            icon: icon('ic_external'),
            info: <Label color="warning">{counts?.totalCourseCount}</Label>,
          },
          {
            title: 'Exams',
            path: paths.dashboard.exams,
            icon: <NoteAltIcon />,
            info: <Label color="warning">{counts?.totalExamCount}</Label>,
          },
          {
            title: 'Student',
            path: paths.dashboard.students,
            icon: <Person3Icon />,
            info: <Label color="warning">{counts?.totalStudentsCount}</Label>,
          },
          {
            title: 'Student Certificates',
            path: paths.dashboard.student_certificates,
            icon: <RedeemIcon />,
            info: <Label color="warning">{counts?.totalStudentCertificateCount}</Label>,
          },
          {
            title: 'Bulk Certificates',
            path: paths.dashboard.bulk_certificate,
            icon: (
              <Iconify icon="material-symbols:drive-folder-upload" sx={{ width: 25, height: 25 }} />
            ),
            info: <Label color="warning">{counts?.totalBulkCertificate}</Label>,
          },
          {
            title: 'Student Exams',
            path: paths.dashboard.student_exams,
            icon: <NoteAltIcon />,
            info: <Label color="warning">{counts?.totalStudentExamCount}</Label>,
          },
          {
            title: 'Resources',
            path: paths.dashboard.resource,
            info: <Label color="warning">{counts?.totalResoucesCount || 0}</Label>,
            icon: icon('ic_kanban'),
          },
          {
            title: 'Subscriptions',
            path: paths.dashboard.subscription,
            info: <Label color="warning">{counts?.totalSubscriptionsCount || 0}</Label>,
            icon: icon('ic_lock'),
          },
          {
            title: 'Other Products',
            path: paths.dashboard.other_product,
            info: <Label color="warning">{counts?.totalOtherProductCount || 0}</Label>,
            icon: icon('ic_job'),
          },
          {
            title: 'Registrations',
            path: paths.dashboard.registrations,
            info: <Label color="warning">{counts?.totalRegistrationCount || 0}</Label>,
            icon: icon('ic_folder'),
          },
        ],
      },

      {
        subheader: 'SETTINGS',
        items: [
          {
            title: 'Regions',
            path: paths.dashboard.region,
            icon: <FlagIcon />,
            // info: <Label color="warning">{counts?.totalCountriesCount}</Label>,
          },
          {
            title: 'Certificate Templates',
            path: paths.dashboard.certificate_template,
            icon: <RedeemIcon />,
            info: <Label color="warning">{counts?.totalCertificateTemplateCount ?? 0}</Label>,
          },
          {
            title: 'Settings',
            path: paths.dashboard.settings,
            icon: <Iconify icon="solar:settings-bold-duotone" width={24} />,
          },
        ],
      },
    ],
    [counts]
  );

  const partnerSideBarData = useMemo(
    () => [
      {
        items: [
          {
            title: 'Dashboard',
            path: `${paths.dashboard.root}/app`,
            icon: <DashboardIcon />,
          },
          {
            title: 'Earnings',
            path: paths.dashboard.earnings,
            icon: icon('ic_invoice'),
            info: <Label color="warning">{counts?.totalEarningsCount}</Label>,
          },
          {
            title: 'Sales',
            path: paths.dashboard.sale,
            icon: icon('ic_order'),
            info: <Label color="warning">{counts?.totalSalesCount}</Label>,
          },
          { title: 'Sales Items', path: paths.dashboard.sale_items, icon: icon('ic_ecommerce') },
          {
            title: 'Partnered Schools',
            path: paths.dashboard.partnership_schools,
            icon: icon('ic_banking'),
            info: <Label color="warning">{counts?.totalSchoolCount}</Label>,
          },
          {
            title: 'Registrations',
            path: paths.dashboard.registrations_partner,
            icon: icon('ic_folder'),
            info: <Label color="warning">{counts?.totalRegistrationCount || 0}</Label>,
          },
        ],
      },
    ],
    [counts]
  );

  const schoolSideBarData = useMemo(
    () => [
      {
        // subheader: 'Dashboard',
        items: [
          { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: <DashboardIcon /> },
          { title: 'Profile', path: paths.dashboard.school_profile, icon: icon('ic_banking') },
          { title: 'Courses', path: paths.dashboard.course, icon: icon('ic_order') },
          { title: 'My Lab', path: paths.dashboard.lab, icon: icon('ic_ecommerce') },
          { title: 'Resources', path: paths.dashboard.school_resource, icon: icon('ic_kanban') },
        ],
      },

      // {
      //   subheader: 'LMS',
      //   items: [{ title: 'Courses', path: paths.dashboard.course, icon: <FlightClassTwoToneIcon /> }],
      // },
    ],
    []
  );
  // sidebar data block

  const data = useMemo(() => {
    const handleSideBarDataWithRole = () => {
      switch (role) {
        case 'super_admin':
          return adminSideBarData;
        case 'partner':
          switch (type) {
            case 'country_head':
              return [
                ...partnerSideBarData,
                {
                  subheader: 'Extra',
                  isCountryHead: true,
                  items: [
                    { title: 'Partners', path: paths.dashboard.partner, icon: <HandshakeIcon /> },
                    {
                      title: 'Partner Type',
                      path: paths.dashboard.partner_type,
                      icon: <PeopleIcon />,
                    },
                    {
                      title: 'Partner Income',
                      path: paths.dashboard.partner_commission,
                      icon: <ChecklistRtlIcon />,
                    },
                  ],
                },
              ];
            case 'sub_head':
              return [
                ...partnerSideBarData,
                {
                  subheader: 'Extra',
                  isCountryHead: true,
                  items: [
                    { title: 'Partners', path: paths.dashboard.partner, icon: <HandshakeIcon /> },
                    // {
                    //   title: 'Partner Type',
                    //   path: paths.dashboard.partner_type,
                    //   icon: <PeopleIcon />,
                    // },
                    // {
                    //   title: 'Partner Income',
                    //   path: paths.dashboard.partner_commission,
                    //   icon: <ChecklistRtlIcon />,
                    // },
                  ],
                },
              ];

            // case 'sub_head':
            //   return [
            //     ...partnerSideBarData,
            //     {
            //       subheader: 'Extra',
            //       isCountryHead: true,
            //       items: [
            //         { title: 'Partners', path: paths.dashboard.partner, icon: <AutoStoriesIcon /> },
            //       ],
            //     },
            //   ];

            default:
              return partnerSideBarData;
          }
        case 'school_user':
          return schoolSideBarData;
        default:
          return [];
      }
    };

    return handleSideBarDataWithRole();
  }, [adminSideBarData, partnerSideBarData, role, schoolSideBarData, type]);

  return data;
}

// export function useNavData() {
//   const data = useMemo(() => adminSideBarData, []);

//   return data;
// }

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };
