import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createPartnerCommission,
  getAllPartnerCommission,
  deletePartnerCommission,
  createPartnerCommissionV4,
  getPartnerCommissionDetails,
  updatePartnerCommissionDetails,
  getPartnerCommissionByPartnerType,
} from '../api/partnerCommission';

export const partnerCommissionSlice = createSlice({
  name: 'partnerCommission',
  initialState: {
    loading: false,
    error: {},
    // staff
    partnerCommission: {},
    staffDropList: [],
    partnerCommissionDetails: {},
    partnerCommissionByPartnerType: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearPartnerCommissionByPartnerType: (state) => {
      state.partnerCommissionByPartnerType = {};
    },
  },
  extraReducers: {
    // create staff details
    [createPartnerCommission.pending]: (state) => {
      state.loading = true;
    },
    [createPartnerCommission.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.partnerCommission;

      // const oldPartnerCommission = jsonState?.partnerCommissions;

      const modifiedPartnerCommissionList = {
        ...jsonState,
        // partnerCommissions: Array.isArray(oldPartnerCommission)
        //   ? [action.payload, ...oldPartnerCommission]
        //   : [action.payload],
        partnerCommissions: action.payload,
      };
      state.loading = false;
      state.partnerCommissionDetails = action.payload;
      state.partnerCommission = modifiedPartnerCommissionList;
      state.error = {};
    },
    [createPartnerCommission.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createPartnerCommissionV4.pending]: (state) => {
      state.loading = true;
    },
    [createPartnerCommissionV4.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.partnerCommission;

      // const oldPartnerCommission = jsonState?.partnerCommissions;

      const modifiedPartnerCommissionList = {
        ...jsonState,
        // partnerCommissions: Array.isArray(oldPartnerCommission)
        //   ? [action.payload, ...oldPartnerCommission]
        //   : [action.payload],
        partnerCommissions: action.payload,
      };
      state.loading = false;
      state.partnerCommissionDetails = action.payload;
      state.partnerCommission = modifiedPartnerCommissionList;
      state.error = {};
    },
    [createPartnerCommissionV4.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all partnerCommission
    [getAllPartnerCommission.pending]: (state) => {
      state.loading = true;
    },
    [getAllPartnerCommission.fulfilled]: (state, action) => {
      state.loading = false;
      state.partnerCommission = action.payload;
      state.error = {};
    },
    [getAllPartnerCommission.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getPartnerCommissionDetails.pending]: (state) => {
      state.loading = true;
    },
    [getPartnerCommissionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.partnerCommissionDetails = action.payload;
      state.error = {};
    },
    [getPartnerCommissionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updatePartnerCommissionDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updatePartnerCommissionDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.partnerCommissionDetails = action.payload;
    //   state.error = {};
    // },
    [updatePartnerCommissionDetails.fulfilled]: (state, action) => {
      console.log(action.payload);
      const jsonState = covertToJSON(state)?.partnerCommission;
      console.log(jsonState);
      const modifiedPartnerCommissionList = {
        ...jsonState,
        response: {
          ...jsonState.response,
          partnerCommissions: jsonState.response?.partnerCommissions?.map((i) =>
            i._id === action.payload._id ? { ...i, ...action?.payload } : i
          ),
        },
      };
      // console.log(modifiedPartnerCommissionList);

      state.loading = false;
      // state.partnerCommission = modifiedPartnerCommissionList;
      // state.partnerCommissionDetails = action.payload;
      state.partnerCommission = modifiedPartnerCommissionList;
      state.error = {};
    },
    // [updatePartnerCommissionDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.partnerCommission;
    //   console.log(jsonState)
    //   const modifiedPartnerCommissionList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.partnerCommissionDetails = action.payload;
    //   state.partnerType = modifiedPartnerCommissionList;
    //   state.error = {};
    // },
    [updatePartnerCommissionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deletePartnerCommission.pending]: (state) => {
      state.loading = true;
    },
    [deletePartnerCommission.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.partnerCommission;
      // console.log(jsonState);
      const modifiedPartnerCommissionList = {
        ...jsonState,
        response: {
          ...jsonState.response,
          partnerCommissions: jsonState.response?.partnerCommissions?.filter(
            (partnerCommission) => partnerCommission._id !== action.payload
          ),
        },
        // partnerCommission: jsonState.response?.partnerCommissions?.filter(
        //   (partnerCommission) => partnerCommission._id !== action.payload
        // ),
      };

      // console.log(action.payload);
      // console.log(modifiedPartnerCommissionList);
      state.loading = false;
      state.partnerCommission = modifiedPartnerCommissionList;
      state.error = {};
    },
    [deletePartnerCommission.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getPartnerCommissionByPartnerType.pending]: (state) => {
      state.loading = true;
    },
    [getPartnerCommissionByPartnerType.fulfilled]: (state, action) => {
      state.loading = false;
      state.partnerCommissionByPartnerType = action.payload;
      state.error = {};
    },
    [getPartnerCommissionByPartnerType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearPartnerCommissionByPartnerType } =
  partnerCommissionSlice.actions;

export default partnerCommissionSlice.reducer;
