import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
// eslint-disable-next-line import/no-extraneous-dependencies
import { HTML5Backend } from 'react-dnd-html5-backend';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDrag, useDrop, DndProvider } from 'react-dnd';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Chip,
  Grid,
  Stack,
  Button,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';

import { fData } from 'src/utils/format-number';

import download from 'src/assets/other/download.png';

import Iconify from '../iconify';
import { post } from '../../server/api/http';
import { RHFUploadAvatar } from '../hook-form';
import ImageDeleteError from './ImageDeleteError';
import { activeSnack } from '../../server/store/common';

export const FileUploadCustom = ({
  title,
  uploadUrl,
  deleteUrl,
  setImagesPreview,
  imagesPreview,
  isMultiple,
  theme,
  onChangeUpdate,
  isUpdate,
  isNoPreview,
  type,
  bannerTab,
  isSection,
  isResource,
}) => {
  const dispatch = useDispatch();
  // const uploadBaseUrl = `/file/admin/upload`;
  // const deleteDefaultUrl = deleteUrl || '/file/admin/delete';
  const uploadBaseUrl = `/file/v5/admin/upload/folder`;
  const deleteDefaultUrl = deleteUrl || '/file/v5/admin/delete';
  const imageSize = isResource ? 500 : 2;

  const [fileSizeIssue, setFileSizeIssue] = useState('');
  const [deleteLoad, setDeleteLoad] = useState({
    status: false,
    id: '',
  });
  const [uploadLoad, setUploadLoad] = useState(false);

  const [deleteErrorPopup, setDeleteErrorPopup] = useState({
    status: false,
    imageId: '',
  });

  const handleDeleteFromLocal = () => {
    setImagesPreview(imagesPreview?.filter((img) => img.public_id !== deleteErrorPopup?.imageId));
    setDeleteErrorPopup({
      status: false,
      imageId: '',
    });
    if (isUpdate && onChangeUpdate) {
      onChangeUpdate(deleteErrorPopup?.imageId, 'remove');
    }
  };

  // size of the file
  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (bytes === 0) return '0 Byte';
    return {
      size: `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`,
      sizeInKb: Math.round(bytes / 1024),
    };
  }

  // Function to handle file selection
  const handleFileSelect = (acceptedFiles) => {
    setUploadLoad(true);

    const files = acceptedFiles;

    const { size, sizeInKb } = bytesToSize(files[0]?.size);

    if (sizeInKb >= imageSize * 1024) {
      setFileSizeIssue(`${size} file size is too large - Max Limit ${imageSize}MB`);
    } else {
      setFileSizeIssue('');
      uploadingImage(files);
    }

    setUploadLoad(false);
  };

  const uploadingImage = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          try {
            setUploadLoad(true);

            const res = await post(uploadBaseUrl, { file: reader.result });

            if (res) {
              setImagesPreview([...imagesPreview, res]);
              dispatch(activeSnack({ type: 'success', message: 'Uploaded SuccessFully' }));
              setUploadLoad(false);
            }
          } catch (error) {
            setUploadLoad(false);
            dispatch(activeSnack({ type: 'error', message: 'Upload Fail' }));
          }
        }
      };

      reader.readAsDataURL(file);
    });
  };

  // Hook for handling file drop and selection
  const { getRootProps, getInputProps } = useDropzone({
    // accept: '.pdf', // Change the file extension based on your requirement
    multiple: false,
    onDrop: handleFileSelect,
  });

  const handleRemove = async (id) => {
    try {
      setDeleteLoad({
        status: true,
        id,
      });
      const res = await post(deleteDefaultUrl, {
        public_id: id,
      });
      if (
        res?.result === 'ok' ||
        res?.result === 'not found' ||
        res?.message === 'File deleted successfully'
      ) {
        setImagesPreview(imagesPreview?.filter((img) => img.public_id !== id));
        dispatch(activeSnack({ type: 'success', message: 'File removed successfully' }));
      }
      setDeleteLoad({
        status: false,
        id,
      });
      if (
        isUpdate &&
        onChangeUpdate &&
        res?.message !== `Error deleting file: ENOENT: no such file or directory, unlink '${id}'`
      ) {
        onChangeUpdate(id, 'remove');
      }
      if (
        res?.message === `Error deleting file: ENOENT: no such file or directory, unlink '${id}'`
      ) {
        setDeleteErrorPopup({
          status: true,
          imageId: id,
        });
      }
    } catch (error) {
      setDeleteLoad({
        status: false,
        id,
      });
      dispatch(activeSnack({ type: 'error', message: 'Image delete fail, try again' }));
    }
  };

  const handleDropNewFile = (file) => {
    handleFileSelect(file);
    theme?.handleDrop(file);
  };

  const extension = imagesPreview?.length && imagesPreview[0]?.url?.split('.').pop().toLowerCase();

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...imagesPreview];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImagesPreview(updatedImages);
  };

  return (
    <div>
      {deleteErrorPopup?.status && (
        <ImageDeleteError
          setOpen={setDeleteErrorPopup}
          open={deleteErrorPopup}
          handleDeleteFromLocal={handleDeleteFromLocal}
        />
      )}
      {!isMultiple && imagesPreview?.length >= 1 ? (
        ''
      ) : (
        <>
          {theme?.type === 'standard' && (
            <RHFUploadAvatar
              handleRemoveImage={() => handleRemove(imagesPreview[0]?.public_id)}
              imagesPreview={imagesPreview}
              name=""
              maxSize={imageSize * 1024 * 1024}
              onDrop={handleDropNewFile}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(imageSize * 1024 * 1024)}
                </Typography>
              }
            />
          )}

          {theme?.type === 'basic' && type !== 'banner' && (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Stack flexDirection="row" alignItems="center">
                <Button variant="outlined" color="inherit" sx={{ color: 'gray' }}>
                  {title || 'Select File'}{' '}
                  {uploadLoad && <CircularProgress size={14} sx={{ mx: 1 }} />}
                </Button>
                {fileSizeIssue && (
                  <Chip sx={{ mx: 1 }} label={fileSizeIssue} color="error" variant="outlined" />
                )}
              </Stack>
            </div>
          )}
        </>
      )}

      {theme?.type === 'basic' && imagesPreview?.length >= 1 && !isNoPreview && (
        <Stack flexDirection="row">
          {imagesPreview?.map((img, key) => (
            <Stack
              key={key}
              sx={{ mt: 3, width: '100%', backgroundColor: 'ThreeDFace', borderRadius: 1, p: 1 }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mr={2}
            >
              {extension === 'pdf' ? (
                <Stack direction="row" alignItems="center">
                  <img
                    alt={img?.public_id}
                    src={download}
                    style={{
                      width: '80px',
                      height: '60px',
                      objectFit: 'contain',
                      filter:
                        deleteLoad?.status && deleteLoad?.id === img?.public_id
                          ? 'grayscale(1)'
                          : 'none',
                    }}
                  />
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '30px' }}>
                    <Typography sx={{ fontSize: '12px' }}>{img?.url}</Typography>
                  </Box>
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center">
                  <img
                    alt={img?.public_id}
                    src={img?.url}
                    style={{
                      width: '80px',
                      height: '60px',
                      objectFit: 'contain',
                      filter:
                        deleteLoad?.status && deleteLoad?.id === img?.public_id
                          ? 'grayscale(1)'
                          : 'none',
                    }}
                  />
                </Stack>
              )}
              {deleteLoad?.status && deleteLoad?.id === img?.public_id ? (
                <CircularProgress size={14} sx={{ mx: 1 }} />
              ) : (
                <IconButton
                  onClick={() => handleRemove(img?.public_id)}
                  color="error"
                  sx={{ width: 30, height: 30, mx: 1 }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
          ))}
        </Stack>
      )}

      {theme?.type === 'basic' && type === 'banner' && (
        <>
          <DndProvider backend={HTML5Backend}>
            <Grid item container spacing={2}>
              {imagesPreview?.map((img, index) => (
                <BannerImageComponent
                  key={img.public_id}
                  index={index}
                  moveImage={moveImage}
                  img={img}
                  handleRemove={handleRemove}
                  deleteLoad={deleteLoad}
                  bannerTab={bannerTab}
                />
                // <Grid item md={bannerTab === 'mobile' ? 4 : 6} key={key}>
                //   <Stack position="relative">
                //     <img
                //       alt={img?.public_id}
                //       src={img?.url}
                //       style={{
                //         aspectRatio: bannerTab === 'mobile' ? 1 / 1 : 16 / 9,
                //         objectFit: 'cover',
                //         borderRadius: 10,
                //         filter:
                //           deleteLoad?.status && deleteLoad?.id === img?.public_id
                //             ? 'grayscale(1)'
                //             : 'none',
                //       }}
                //     />
                //     {deleteLoad?.status && deleteLoad?.id === img?.public_id ? (
                //       <CircularProgress size={14} sx={{ mx: 1 }} />
                //     ) : (
                //       <Box position="absolute" right={0} top={5}>
                //         <IconButton
                //           onClick={() => handleRemove(img?.public_id)}
                //           sx={{ width: 30, height: 30, mx: 1, backgroundColor: '#DFE3E8' }}
                //         >
                //           <DeleteIcon fontSize="small" color="error" />
                //         </IconButton>
                //       </Box>
                //     )}
                //   </Stack>
                // </Grid>
              ))}
            </Grid>
          </DndProvider>
          <Stack
            mt={3}
            sx={{
              backgroundColor: '#DFE3E8',
              borderRadius: 2,
              width: bannerTab === 'mobile' ? '33%' : '50%',
              p: 6,
            }}
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Stack
                direction="column"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Iconify icon="ri:upload-cloud-2-line" sx={{ width: 50, height: 50 }} />
                <Typography textAlign="center" variant="subtitle1" py={1}>
                  Click or drop Images here to upload
                </Typography>
                {!isSection && (
                  <Typography textAlign="center" variant="body2" color="#637381" pb={1}>
                    {bannerTab === 'mobile'
                      ? 'Upload images with a 1:1 aspect ratio'
                      : 'Upload images with a 16:5 aspect ratio'}
                  </Typography>
                )}

                <Button variant="contained" color="inherit" size="small">
                  {title || 'Upload Image'}{' '}
                  {uploadLoad && <CircularProgress size={14} sx={{ mx: 1 }} />}
                </Button>
                {fileSizeIssue && (
                  <Chip sx={{ mx: 1 }} label={fileSizeIssue} color="error" variant="outlined" />
                )}
              </Stack>
            </div>
          </Stack>
        </>
      )}
    </div>
  );
};

FileUploadCustom.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    handleDrop: PropTypes.func,
  }),
  uploadUrl: PropTypes.string,
  deleteUrl: PropTypes.string,
  setImagesPreview: PropTypes.func,
  imagesPreview: PropTypes.any,
  isMultiple: PropTypes.bool,
  onChangeUpdate: PropTypes.any,
  isUpdate: PropTypes.any,
  isNoPreview: PropTypes.any,
  type: PropTypes.any,
  bannerTab: PropTypes.any,
  isSection: PropTypes.any,
  isResource: PropTypes.any,
};

FileUploadCustom.defaultProps = {
  theme: {
    type: 'basic',
  },
};

export const BannerImageComponent = ({
  bannerTab,
  img,
  deleteLoad,
  handleRemove,
  index,
  moveImage,
}) => {
  const ItemType = 'IMAGE';

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index }, // Pass the current index
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });
  return (
    <Grid item md={bannerTab === 'mobile' ? 4 : 6}>
      <Stack position="relative">
        <img
          ref={(node) => drag(drop(node))}
          alt={img?.public_id}
          src={img?.url}
          style={{
            aspectRatio: bannerTab === 'mobile' ? 1 / 1 : 16 / 9,
            objectFit: 'cover',
            borderRadius: 10,
            cursor: 'grab',
            opacity: isDragging ? 0.5 : 1,
            filter:
              deleteLoad?.status && deleteLoad?.id === img?.public_id ? 'grayscale(1)' : 'none',
          }}
        />
        {deleteLoad?.status && deleteLoad?.id === img?.public_id ? (
          <CircularProgress size={14} sx={{ mx: 1 }} />
        ) : (
          <Box position="absolute" right={0} top={5}>
            <IconButton
              onClick={() => handleRemove(img?.public_id)}
              sx={{ width: 30, height: 30, mx: 1, backgroundColor: '#DFE3E8' }}
            >
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
        )}
      </Stack>
    </Grid>
  );
};

BannerImageComponent.propTypes = {
  bannerTab: PropTypes.any,
  img: PropTypes.any,
  deleteLoad: PropTypes.any,
  handleRemove: PropTypes.any,
  moveImage: PropTypes.any,
  index: PropTypes.any,
};
