import * as Yup from 'yup';
import * as React from 'react';
import { isNaN } from 'lodash';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Step from '@mui/material/Step';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Button, Container, Typography, CircularProgress } from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

import { colors } from 'src/theme/colors';
import { submitStudentExamInputV5 } from 'src/server/api/studentExam';

import EmptyContent from 'src/components/empty-content/empty-content';
import FormProvider, { RHFRadioGroup } from 'src/components/hook-form';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.stem_green,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.stem_green,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: colors.stem_green,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: colors.stem_green,
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

export default function ExamInputSteppers({ timeOver, resetExam, page, setPage, contentLimit }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const params = useParams();

  const { exams, loading, studentExamLoading } = useSelector((state) => ({
    exams: state.lmsExam.exams,
    loading: state.lmsExam.loading,
    studentExamLoading: state.studentExam.loading,
  }));

  const content = exams?.examContents;

  console.log(exams);

  const total = exams?.total;

  const stepperData = Math.ceil(total / contentLimit);
  const stepsArray = Array.from({ length: stepperData }, (_, index) => index + 1);
  const steps = stepsArray;

  const totalSteps = steps.length;

  const isLastStep = useMemo(() => activeStep === totalSteps - 1, [activeStep, totalSteps]);

  const handleNext = useCallback(() => {
    const newActiveStep = !isLastStep && activeStep + 1;
    setActiveStep(newActiveStep);
    setPage(page + 1);
    localStorage.setItem('p', page + 1);
  }, [isLastStep, activeStep, setPage, page]);

  useEffect(() => {
    const activeStepCheck = localStorage.getItem('p');
    if (isNaN(activeStepCheck) || activeStepCheck === undefined) {
      localStorage.removeItem('p');
    }
    if (activeStepCheck) {
      const stepToNum = parseInt(activeStepCheck, 10);
      setActiveStep(stepToNum - 1);
    }
  }, []);

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  //   setPage(page - 1);
  // };
  const [userInputs, setUserInputs] = useState([]);

  // useEffect(() => {
  //   if (userInputs?.length >= 1) {
  //     localStorage.setItem(
  //       'ans',
  //       JSON.stringify({
  //         student_inputs: userInputs,
  //       })
  //     );
  //   }
  // }, [userInputs]);

  // useEffect(() => {
  //   const storedData = localStorage.getItem('ans');
  //   const parsedData = JSON.parse(storedData);

  //   if (parsedData && parsedData?.student_inputs) {
  //     const studentInputs = parsedData?.student_inputs;
  //     console.log(studentInputs);
  //     if (studentInputs?.length >= 1) {
  //       setUserInputs(studentInputs);
  //     }
  //   }
  // }, []);

  console.log(userInputs);

  const submitStudentExamInputData = useCallback(() => {
    const credentials = {
      state: {
        student_inputs:
          userInputs?.length >= 1
            ? userInputs
            : [
                {
                  student_exam: params?.studentExam,
                },
              ],
      },
      enqueueSnackbar,
      navigate,
      resetExam,
    };
    dispatch(submitStudentExamInputV5(credentials));
  }, [userInputs, params?.studentExam, enqueueSnackbar, navigate, resetExam, dispatch]);

  useEffect(() => {
    if (timeOver) {
      if (isLastStep) {
        submitStudentExamInputData();
      } else {
        handleNext();
      }
    }
  }, [handleNext, isLastStep, submitStudentExamInputData, timeOver]);

  // form
  const Schema = Yup.object().shape({});

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {},
  });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  console.log(isSubmitting);
  console.log(errors);

  const onSubmit = handleSubmit(async (loginFormData) => {
    submitStudentExamInputData();
  });

  console.log(userInputs);

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps?.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>Part {label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Container maxWidth="sm" sx={{ ml: 0 }}>
          {content?.map((question, k) => (
            <RenderForm
              userInputs={userInputs}
              setUserInputs={setUserInputs}
              item={question}
              index={k}
              key={k}
              studentExamsId={params?.studentExam}
            />
          ))}
        </Container>

        {isLastStep && (
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              type="submit"
              sx={{
                borderRadius: 100,
                backgroundColor: colors.stem_blue,
                color: '#fff',
                fontWeight: 400,
                minWidth: 100,
                px: 1,
                mr: 2,
              }}
              color="info"
              variant="contained"
              size="medium"
            >
              Finish
              {studentExamLoading ||
                (isSubmitting && <CircularProgress color="inherit" size={17} sx={{ mr: 1 }} />)}
            </Button>
          </Box>
        )}
      </FormProvider>

      {!isLastStep && (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            sx={{
              borderRadius: 100,
              backgroundColor: colors.stem_blue,
              color: '#fff',
              fontWeight: 400,
              minWidth: 100,
              px: 1,
              mr: 2,
            }}
            color="info"
            variant="contained"
            size="medium"
            endIcon={
              loading ? (
                <CircularProgress color="inherit" size={17} sx={{ mr: 1 }} />
              ) : (
                <KeyboardArrowRightRoundedIcon fontSize="small" />
              )
            }
            disabled={isLastStep}
            onClick={handleNext}
            type="button"
          >
            Next
          </Button>
        </Box>
      )}

      {content?.length <= 0 && (
        <Box>
          <EmptyContent
            filled
            title="No Data"
            sx={{
              py: 10,
            }}
          />
        </Box>
      )}
    </Stack>
  );
}

ExamInputSteppers.propTypes = {
  timeOver: PropTypes.any,
  contentLimit: PropTypes.any,
  setPage: PropTypes.func,
  resetExam: PropTypes.func,
  page: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
  textElement: {
    cursor: 'pointer',
  },
}));

function encryptText(text) {
  return btoa(text);
}

const RenderForm = ({ item, index, setUserInputs, userInputs, studentExamsId }) => {
  const classes = useStyles();

  useEffect(() => {
    const handleCopy = (event) => {
      const selection = window.getSelection();

      const encryptedText = encryptText(selection.toString());
      console.log('Encrypted text:', encryptedText);

      if (selection.anchorNode) {
        event.clipboardData.setData('text/plain', encryptedText);
        event.preventDefault();
      }
    };

    const textElements = document.querySelectorAll('.css-ixccc3-MuiTypography-root');

    textElements.forEach((element) => {
      element.addEventListener('copy', handleCopy);
    });

    return () => {
      textElements.forEach((element) => {
        element.removeEventListener('copy', handleCopy);
      });
    };
  }, []);

  const options = item?.multi_options?.map((option) => ({
    value: option,
    label: option,
  }));

  const handleChangesInputs = (answer) => {
    console.log('data :', answer);

    const modifiedAnswer = {
      student_exam: studentExamsId,
      exam_content: item?._id,
      student_input: answer,
    };

    const existingIndex = userInputs.findIndex(
      (input) => input.exam_content === modifiedAnswer.exam_content
    );

    if (existingIndex !== -1) {
      setUserInputs((prevUserInputs) => {
        const newUserInputs = [...prevUserInputs];
        newUserInputs[existingIndex] = modifiedAnswer;
        return newUserInputs;
      });
    } else {
      setUserInputs((prevUserInputs) => [...prevUserInputs, modifiedAnswer]);
    }
  };

  return (
    <Stack spacing={1} mb={2}>
      <Typography variant="subtitle2" className={classes.textElement}>
        {index + 1}. {item?.question}
      </Typography>
      <RHFRadioGroup
        onChange={(event, value) => {
          handleChangesInputs(value);
        }}
        row
        spacing={4}
        name={`${item?.question}-${index}-${item?._id}`}
        options={options}
      />
    </Stack>
  );
};
RenderForm.propTypes = {
  item: PropTypes.any,
  index: PropTypes.number,
  setUserInputs: PropTypes.func,
  userInputs: PropTypes.any,
  studentExamsId: PropTypes.any,
};

// <Button
// type="button"
// color="inherit"
// disabled={activeStep === 0}
// onClick={handleBack}
// sx={{ mr: 1 }}
// >
// Previous (*remove)
// </Button>
