import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import examReducer from './exam';
import authReducer from './auth';
import saleReducer from './sale';
import talukReducer from './taluk';
import ClassReducer from './class';
import StateReducer from './state';
import commonReducer from './common';
import schoolReducer from './school';
import CourseReducer from './courses';
import studentReducer from './student';
import sessionReducer from './session';
import CountryReducer from './country';
import partnerReducer from './partner';
import earningReducer from './earning';
import addressReducer from './address';
import contentReducer from './content';
import lmsExamReducer from './lmsExam';
import districtReducer from './district';
import settingsReducer from './settings';
import saleItemReducer from './saleItem';
import labModelReducer from './labModel';
import resourceReducer from './resource';
import dashboardReducer from './dashboard';
import excelReducer from './excelDownload';
import schoolUserReducer from './schoolUser';
import partnerTypeReducer from './partnerType';
import schoolClassReducer from './schoolClass';
import studentExamReducer from './studentExam';
import customFieldReducer from './customField';
import schoolCourseReducer from './schoolCourse';
import subscriptionReducer from './subscription';
import otherProductReducer from './otherProduct';
import registrationsReducer from './registrations';
import schoolDivisionReducer from './schoolDivision';
import supportMaterialReducer from './supportMaterial';
import bulkCertificateReducer from './bulkCertificate';
import partnerCommissionReducer from './partnerCommission';
import lmsExamCertificateReducer from './lmsExamCertificate';
import certificateTemplateReducer from './certificateTemplate';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    partnerType: partnerTypeReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    country: CountryReducer,
    state: StateReducer,
    district: districtReducer,
    taluk: talukReducer,
    partner: partnerReducer,
    saleItem: saleItemReducer,
    Course: CourseReducer,
    sale: saleReducer,
    school: schoolReducer,
    partnerCommission: partnerCommissionReducer,
    labModel: labModelReducer,
    schoolUser: schoolUserReducer,
    class: ClassReducer,
    schoolClass: schoolClassReducer,
    schoolCourse: schoolCourseReducer,
    schoolDivision: schoolDivisionReducer,
    earning: earningReducer,
    address: addressReducer,
    session: sessionReducer,
    content: contentReducer,
    settings: settingsReducer,
    supportMaterial: supportMaterialReducer,
    exam: examReducer,
    student: studentReducer,
    lmsExam: lmsExamReducer,
    lmsExamCertificate: lmsExamCertificateReducer,
    studentExam: studentExamReducer,
    excel: excelReducer,
    certificateTemplate: certificateTemplateReducer,
    resource: resourceReducer,
    subscription: subscriptionReducer,
    otherProducts: otherProductReducer,
    registrations: registrationsReducer,
    customField: customFieldReducer,
    bulkCertificate: bulkCertificateReducer,
  },
});
